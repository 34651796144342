import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePublicPayMutation } from '../components/publicPaymentApiSlice';
import { useAddNewPublicReservationMutation } from '../components/publicReservationsApiSlice';
import PulseLoader from 'react-spinners/PulseLoader';
import { Box, Button, Typography, Card, CardContent, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const PaymentForm = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [addNewPublicReservation, { isLoading, isError, error }] = useAddNewPublicReservationMutation();
    const [PublicPay] = usePublicPayMutation();
    const { i18n, t } = useTranslation(); // Use the 't' function for translation
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const formatDate = (date) => new Date(date).toLocaleDateString();

    const treatAsUTC = (date) => {
        const result = new Date(date);
        result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
        return result;
    };

    const daysBetween = (startDate, endDate) => {
        const millisecondsPerDay = 24 * 60 * 60 * 1000;
        return (treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerDay;
    };

    const nights = daysBetween(state.checkInDate, state.checkOutDate);
    const price = state.roomPrice * nights;

    const handlePayInPerson = async () => {
        const myDocument = {
            room: state.roomName,
            name: state.name,
            email: state.email,
            phone: state.phone,
            adults: state.adults,
            kids: state.kids,
            checkInDate: state.checkInDate,
            checkOutDate: state.checkOutDate,
            paymentStatus: 'pending',
            paymentAmount: price,
            note: state.note,
        };

        const reservation = await addNewPublicReservation(myDocument);
        if (reservation) {
            navigate('/book/receipt', { state: { reservation } });
        }
    };

    const handlePay = async () => {
        const myDocument = {
            room: state.roomName,
            name: state.name,
            email: state.email,
            phone: state.phone,
            adults: state.adults,
            kids: state.kids,
            checkInDate: state.checkInDate,
            checkOutDate: state.checkOutDate,
            paymentStatus: 'pending',
            paymentAmount: price,
            note: state.note,
            nights: nights,
            language: i18n.language === 'en-US' ? 'en' : i18n.language
        };

        const sessionURL = await PublicPay(myDocument);
        window.location.replace(sessionURL?.data?.message);
    };

    console.log(i18n.language)

    const handleCancel = () => {
        navigate('/');
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
                backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
            }}
        >
            <Card
                sx={{
                    minWidth: isMobile ? '90%' : '50%',
                    bgcolor: 'rgba(255, 255, 255, 0.85)', // Transparent white background
                    borderRadius: 2,
                    boxShadow: 3, // Adds some shadow for a card effect
                    padding: 3, // Padding inside the card
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: 'auto',
                }}
            >
                <CardContent>
                    <Typography
                        variant="h4" // Increased to h4 for larger text
                        gutterBottom
                        sx={{ fontSize: isMobile ? '1.5rem' : '2rem' }}
                    >
                        {t('PaymentForm.guestName')}: {state.name}
                    </Typography>
                    <Typography
                        variant="h5" // Increased to h5 for readability
                        gutterBottom
                        sx={{ fontSize: isMobile ? '1.3rem' : '1.8rem' }}
                    >
                        {t('PaymentForm.email')}: {state.email}
                    </Typography>
                    <Typography
                        variant="h5"
                        gutterBottom
                        sx={{ fontSize: isMobile ? '1.3rem' : '1.8rem' }}
                    >
                        {t('PaymentForm.phone')}: {state.phone}
                    </Typography>
                    <Typography
                        variant="h5"
                        gutterBottom
                        sx={{ fontSize: isMobile ? '1.3rem' : '1.8rem' }}
                    >
                        {t('PaymentForm.checkInDate')}: {formatDate(state.checkInDate)}
                    </Typography>
                    <Typography
                        variant="h5"
                        gutterBottom
                        sx={{ fontSize: isMobile ? '1.3rem' : '1.8rem' }}
                    >
                        {t('PaymentForm.checkOutDate')}: {formatDate(state.checkOutDate)}
                    </Typography>
                    <Typography
                        variant="h5"
                        gutterBottom
                        sx={{ fontSize: isMobile ? '1.3rem' : '1.8rem' }}
                    >
                        {t('PaymentForm.nights')}: {nights}
                    </Typography>
                    <Typography
                        variant="h5"
                        gutterBottom
                        sx={{ fontSize: isMobile ? '1.3rem' : '1.8rem' }}
                    >
                        {t('PaymentForm.room')}: {state.roomName}
                    </Typography>
                    <Typography
                        variant="h5"
                        gutterBottom
                        sx={{ fontSize: isMobile ? '1.3rem' : '1.8rem' }}
                    >
                        {t('PaymentForm.paymentAmount')}: {price}
                    </Typography>
                    <Typography
                        variant="h5"
                        gutterBottom
                        sx={{ fontSize: isMobile ? '1.3rem' : '1.8rem' }}
                    >
                        {t('PaymentForm.adults')}: {state.adults}
                    </Typography>
                    <Typography
                        variant="h5"
                        gutterBottom
                        sx={{ fontSize: isMobile ? '1.3rem' : '1.8rem' }}
                    >
                        {t('PaymentForm.children')}: {state.kids}
                    </Typography>
                    <Typography
                        variant="h5"
                        gutterBottom
                        sx={{ fontSize: isMobile ? '1.3rem' : '1.8rem' }}
                    >
                        {t('PaymentForm.notes')}: {state.note}
                    </Typography>

                    {isError && (
                        <Typography color="error" sx={{ fontSize: isMobile ? '1.2rem' : '1.5rem' }}>
                            {error}
                        </Typography>
                    )}
                </CardContent>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        justifyContent: 'space-between', // Ensures space between buttons
                        gap: 2,
                        mt: 2,
                    }}
                >
                    {/* Cancel Button on the left */}
                    <Button
                        variant="outlined"
                        color="error"
                        onClick={handleCancel}
                        sx={{
                            borderRadius: 3,
                            padding: isMobile ? '10px' : '0.75em',
                            width: isMobile ? '100%' : 'auto',
                            fontSize: '1.2rem', // Increase font size
                            order: isMobile ? 1 : 0, // Ensure Cancel button appears first in mobile layout
                        }}
                    >
                        {t('PaymentForm.button.cancel')}
                    </Button>

                    {/* Container for the Pay buttons on the right */}
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        {/* Pay at Property Button */}
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handlePayInPerson}
                            sx={{
                                borderRadius: 3,
                                padding: isMobile ? '10px' : '0.75em',
                                width: isMobile ? '100%' : 'auto',
                                fontSize: '1.2rem', // Increase font size
                            }}
                        >
                            {isLoading ? <PulseLoader size={10} /> : t('PaymentForm.button.payAtProperty')}
                        </Button>

                        {/* Pay Online Button */}
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handlePay}
                            sx={{
                                borderRadius: 3,
                                padding: isMobile ? '10px' : '0.75em',
                                width: isMobile ? '100%' : 'auto',
                                fontSize: '1.2rem', // Increase font size
                            }}
                        >
                            {isLoading ? <PulseLoader size={10} /> : t('PaymentForm.button.payOnline')}
                        </Button>
                    </Box>
                </Box>

            </Card>
        </Box>
    );
};

export default PaymentForm;
