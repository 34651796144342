import { useState, useEffect } from "react";
import { useAddNewUserMutation } from "./usersApiSlice";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { ROLES } from "../../config/roles";
import {
    Box,
    Typography,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    CircularProgress,
    Alert,
} from "@mui/material";

const USER_REGEX = /^[A-z]{3,20}$/;
const PWD_REGEX = /^[A-z0-9!@#$%]{4,12}$/;

const NewUserForm = () => {
    const [addNewUser, { isLoading, isSuccess, isError, error }] =
        useAddNewUserMutation();

    const navigate = useNavigate();

    const [username, setUsername] = useState("");
    const [validUsername, setValidUsername] = useState(false);
    const [password, setPassword] = useState("");
    const [validPassword, setValidPassword] = useState(false);
    const [roles, setRoles] = useState(["Employee"]);

    useEffect(() => {
        setValidUsername(USER_REGEX.test(username));
    }, [username]);

    useEffect(() => {
        setValidPassword(PWD_REGEX.test(password));
    }, [password]);

    useEffect(() => {
        if (isSuccess) {
            setUsername("");
            setPassword("");
            setRoles([]);
            navigate("/dash/users");
        }
    }, [isSuccess, navigate]);

    const onUsernameChanged = (e) => setUsername(e.target.value);
    const onPasswordChanged = (e) => setPassword(e.target.value);
    const onRolesChanged = (e) => setRoles(e.target.value);

    const canSave =
        [roles.length, validUsername, validPassword].every(Boolean) && !isLoading;

    const onSaveUserClicked = async (e) => {
        e.preventDefault();
        if (canSave) {
            await addNewUser({ username, password, roles });
        }
    };

    return (
        <Box
            sx={{
                maxWidth: 600,
                mx: "auto",
                p: 3,
                bgcolor: "background.paper",
                boxShadow: 3,
                borderRadius: 2,
            }}
        >
            {isError && (
                <Alert severity="error" sx={{ mb: 2 }}>
                    {error?.data?.message}
                </Alert>
            )}

            <Typography variant="h4" gutterBottom>
                New User
            </Typography>

            <form onSubmit={onSaveUserClicked}>
                <TextField
                    label="Username (3-20 letters)"
                    variant="outlined"
                    fullWidth
                    sx={{ mb: 2 }}
                    value={username}
                    onChange={onUsernameChanged}
                    error={!validUsername && username !== ""}
                    helperText={
                        !validUsername && username !== "" && "Invalid username format"
                    }
                    required
                />

                <TextField
                    label="Password (4-12 chars incl. !@#$%)"
                    type="password"
                    variant="outlined"
                    fullWidth
                    sx={{ mb: 2 }}
                    value={password}
                    onChange={onPasswordChanged}
                    error={!validPassword && password !== ""}
                    helperText={
                        !validPassword && password !== "" && "Invalid password format"
                    }
                    required
                />

                <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                    <InputLabel>Assigned Roles</InputLabel>
                    <Select
                        multiple
                        value={roles}
                        onChange={onRolesChanged}
                        label="Assigned Roles"
                        renderValue={(selected) => selected.join(", ")}
                        error={!roles.length}
                    >
                        {Object.values(ROLES).map((role) => (
                            <MenuItem key={role} value={role}>
                                {role}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={!canSave}
                        startIcon={
                            isLoading ? <CircularProgress size={20} /> : <FontAwesomeIcon icon={faSave} />
                        }
                    >
                        Save
                    </Button>
                </Box>
            </form>
        </Box>
    );
};

export default NewUserForm;
