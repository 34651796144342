import React from 'react';
import styles from './HotelName.module.css';

const HotelName = () => {
    // Get hotel name from environment variable
    const hotelName = process.env.REACT_APP_HOTEL_NAME;

    return (
        <div className={styles.sections}>
            <div className={styles.hotelNameSection}>
                <h1 className={styles.hotelName}>{hotelName}</h1>
            </div>
            <div className={styles.scrollDown}>
                <span className={styles.arrow}></span>
            </div>
        </div>
    );
};

export default HotelName;
