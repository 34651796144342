import { apiSlice } from "../../app/api/apiSlice"

export const payApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getSession: builder.mutation({
            query: (sessionId) => ({
                url: `/payment/checkout-session/`,
                method: 'POST',
                body: {
                    sessionId
                },
            }),
        }),
        pay: builder.mutation({
            query: submit => ({
                url: '/payment/create-checkout-session',
                method: 'POST',
                body: {
                    ...submit,
                }
            })
        }),
        webhook: builder.mutation({
            query: submit => ({
                url: '/payment/webhook',
                method: 'POST',
                body: {
                    ...submit,
                }
            })
        }),
    }),
})

export const {
    useGetSessionMutation,
    usePayMutation,
} = payApiSlice