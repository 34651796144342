import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BarLoader } from 'react-spinners';
import { Grid, Container, Paper, Typography } from "@mui/material";
import styles from './Highlights.module.css';

const Highlights = ({ className }) => {
    const { t } = useTranslation();
    const [img1, setImg1] = useState(false);
    const [img2, setImg2] = useState(false);
    const [img3, setImg3] = useState(false);

    const highlights = (
        <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
                <Paper className={styles.highlightsContainer} elevation={3}>
                    <img
                        src="https://images.pexels.com/photos/2416419/pexels-photo-2416419.jpeg"
                        alt={t('Highlights.highlight1.title')}
                        className={styles.image}
                        onLoad={() => setImg1(true)}
                    />
                    <Typography className={styles.description} variant="body1">
                        {t('Highlights.highlight1.description')}
                    </Typography>
                </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
                <Paper className={styles.highlightsContainer} elevation={3}>
                    <img
                        src="https://images.pexels.com/photos/12194503/pexels-photo-12194503.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                        alt={t('Highlights.highlight2.title')}
                        className={styles.image}
                        onLoad={() => setImg2(true)}
                    />
                    <Typography className={styles.description} variant="body1">
                        {t('Highlights.highlight2.description')}
                    </Typography>
                </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
                <Paper className={styles.highlightsContainer} elevation={3}>
                    <img
                        src="https://images.pexels.com/photos/4552990/pexels-photo-4552990.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                        alt={t('Highlights.highlight3.title')}
                        className={styles.image}
                        onLoad={() => setImg3(true)}
                    />
                    <Typography className={styles.description} variant="body1">
                        {t('Highlights.highlight3.description')}
                    </Typography>
                </Paper>
            </Grid>
        </Grid>
    );

    const loadingBar =
        <div className={styles.loadingContainer}>
            <BarLoader width={"100%"} loading={!(img1 & img2 & img3)} />
            <Typography>{t('loading')}</Typography>
        </div>;

    return (
        <Container className={className}>
            {!(img1 && img2 && img3) && loadingBar}
            <div className={!(img1 && img2 && img3) ? styles.hidden : styles.visible}>
                {highlights}
            </div>
        </Container>
    );
};

export default Highlights;
