import { useParams } from 'react-router-dom'
import EditReservationForm from './EditReservationForm'
import { useGetReservationsQuery } from './reservationsApiSlice'
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../hooks/useTitle'
import useAuth from '../../hooks/useAuth'

const EditReservation = () => {

    useTitle('Edit Reservation')

    const { username, isManager, isAdmin } = useAuth()

    const { id } = useParams()

    const { reservation } = useGetReservationsQuery("reservationsList", {
        selectFromResult: ({ data }) => ({
            reservation: data?.entities[id]
        }),
    })

    if (!reservation) return <PulseLoader color={"#FFF"} />

    if (!isManager && !isAdmin) {
        if (reservation.name !== username) {
            return <p className="errmsg">No access</p>
        }
    }

    const content = <EditReservationForm reservation={reservation} />

    return content
}
export default EditReservation