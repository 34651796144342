import React, { useState } from 'react';
import Calendar from 'react-calendar';
import { useGetRoomsQuery } from '../rooms/roomsApiSlice';
import { Box, Typography } from '@mui/material';
import PulseLoader from 'react-spinners/PulseLoader';
import 'react-calendar/dist/Calendar.css';
import styles from './AllCalendar.module.css';

const AllCalendar = () => {
    const { data: rooms, isLoading, isError, error, isSuccess } = useGetRoomsQuery('roomsList', {
        pollingInterval: 5000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
    });

    const [value, setValue] = useState(new Date());

    let content;

    if (isLoading) content = <PulseLoader color={'#FFF'} />;

    if (isError) {
        content = <Typography color="error">{error?.data?.message}</Typography>;
    }

    if (isSuccess) {
        const roomObjects = rooms?.ids.map(id => rooms?.entities[id]);

        // Function to check if a room is vacant on a specific date
        const hasDate = (testDate) => {
            const testDateObj = new Date(testDate).setHours(0, 0, 0, 0);
            return roomObjects.some(room => {
                const dates = getDates(room?.datesOccupied);
                return !dates.find(date => new Date(date).setHours(0, 0, 0, 0) === testDateObj);
            });
        };

        // Function to extract dates from the occupied date objects
        const getDates = (objects) => objects.map(dateObj => dateObj?.date);

        // Get list of vacant rooms for the selected date
        const vacantRooms = roomObjects
            .filter(room => {
                const dates = getDates(room?.datesOccupied);
                return !dates.find(date => new Date(date).toLocaleDateString() === new Date(value).toLocaleDateString());
            })
            .map(room => <Typography key={room.roomName} variant="h6">{room.roomName}</Typography>);

        content = (
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' }, gap: 2, alignItems: 'flex-start' }}>
                {/* Calendar Section */}
                <div className={styles.calendarWrapper} style={{ flex: 1 }}>
                    <Calendar
                        minDetail="month"
                        onChange={setValue}
                        value={value}
                        // Highlight occupied dates
                        tileClassName={({ date }) => {
                            if (!hasDate(date)) return styles.notVacant;
                            if (date.toDateString() === new Date().toDateString()) return styles.today;
                            if (date.toDateString() === new Date(value).toDateString()) return styles.selected;
                            return null;
                        }}
                    />
                </div>

                {/* Vacant Rooms Section */}
                <Box sx={{ flex: 1 }}>
                    <Typography variant="h4" component="h1">
                        Rooms vacant on {value.toLocaleDateString()}
                    </Typography>
                    {!vacantRooms.length && <Typography variant="h6">No rooms vacant</Typography>}
                    {vacantRooms}
                </Box>
            </Box>
        );
    }

    return content;
};

export default AllCalendar;
