import React from 'react';
import { Card, CardContent, CardMedia, Typography, Box } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import { useTranslation } from 'react-i18next';

const Directions = () => {
    // Get hotel name from environment variable
    const hotelName = process.env.REACT_APP_HOTEL_NAME;
    const { t } = useTranslation();

    return (
        <Card sx={{ maxWidth: 600, m: '20px auto', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)' }}>
            <CardMedia
                component="img"
                height="300"
                image="https://thumbs.dreamstime.com/b/small-hotel-19940539.jpg"
                alt="Hotel Image"
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {t('Directions.hotelName', { hotelName })}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {t('Directions.hotelDescription')}
                </Typography>
                <Box sx={{ mt: 2 }}>
                    <Typography
                        component="a"
                        href="https://www.google.com/maps/search/?api=1&query=RH57%2BW8F%2C+Mae+Suai%2C+Mae+Suai+District%2C+Chiang+Rai+57180"
                        target="_blank"
                        rel="noopener noreferrer"
                        variant="body2"
                        color="text.secondary"
                        sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none', cursor: 'pointer' }}
                    >
                        <LocationOnIcon sx={{ mr: 1 }} /> {t('Directions.address')}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                        <PhoneIcon sx={{ mr: 1 }} /> {t('Directions.phoneNumber1')}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                        <PhoneIcon sx={{ mr: 1 }} /> {t('Directions.phoneNumber2')}
                    </Typography>
                </Box>
                <Box sx={{ mt: 2 }}>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3778.6905641818293!2d99.55511821526029!3d19.793073886676454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30d7161c486a3a6b%3A0x7ed2dc6ffd30651d!2sRH57%2BW8F%2C%20Mae%20Suai%2C%20Mae%20Suai%20District%2C%20Chiang%20Rai%2057180!5e0!3m2!1sen!2sth!4v1725282218095"
                        width="100%"
                        height="200"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                        title={t('Directions.hotelLocation')}
                    ></iframe>
                </Box>
            </CardContent>
        </Card>
    );
}

export default Directions;
