import React from 'react';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide, Box, MobileStepper, Paper } from "@mui/material";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { BarLoader } from 'react-spinners';
import { useTheme } from "@mui/material/styles";
import { useNavigate } from 'react-router-dom';
import { useGetPublicRoomsQuery } from './publicRoomsApiSlice';
import ImagePopup from './ImagePopup';
import { useLocation } from 'react-router-dom';
import PlaceHolder from '../img/placeholder-image.webp';
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const RoomCard = ({ roomId, checkInDate, checkOutDate }) => {
    const location = useLocation();
    const theme = useTheme();
    const navigate = useNavigate();
    const [swiperInstance, setSwiperInstance] = React.useState(null);

    const { room, isLoading, error } = useGetPublicRoomsQuery("roomsList", {
        selectFromResult: ({ data }) => ({
            room: data?.entities[roomId],
            isLoading: data?.isLoading,
            error: data?.error,
        }),
    });

    const [openCard, setOpenCard] = React.useState(false);
    const [activeStep, setActiveStep] = React.useState(0);
    const [imageLoading, setImageLoading] = React.useState(true);

    if (isLoading) return <BarLoader />;
    if (error) return <div>Error loading room data</div>;
    if (!room) return null;

    const images = room?.roomImages || [];
    const labels = room?.imageLabels || [];
    const roomName = room?.roomName || 'Unknown Room';
    const roomDetail = room?.roomDescription || 'No description available';
    const price = room?.roomPrice ? `${room.roomPrice}` : 'Price not available';
    const maxSteps = images.length;

    const handleClickOpenCard = () => setOpenCard(true);
    const handleCloseCard = () => setOpenCard(false);

    const handleNext = () => {
        if (swiperInstance && activeStep < maxSteps - 1) {
            swiperInstance.slideNext();
        }
    };

    const handleBack = () => {
        if (swiperInstance && activeStep > 0) {
            swiperInstance.slidePrev();
        }
    };

    const handleSlideChange = (swiper) => {
        setActiveStep(swiper.activeIndex);
    };

    const handleClickOpen = () => {
        navigate('/book/information', {
            state: { roomName, checkInDate, checkOutDate, roomPrice: price },
        });
    };

    const handleClickGoBook = () => navigate('/book')

    const book = <Button onClick={handleClickOpen}>Book {price} per night</Button>
    const goBook = <Button onClick={handleClickGoBook}>Book Now</Button>

    const roomCard = (
        <Card sx={{ maxWidth: 1 }}>
            <CardActionArea onClick={handleClickOpenCard}>
                {imageLoading && (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '140px' }}>
                        <BarLoader />
                    </div>
                )}
                <CardMedia
                    component="img"
                    height="140"
                    image={images[0] || PlaceHolder}
                    alt={labels[0] || 'Room image'}
                    onLoad={() => setImageLoading(false)}
                    onError={() => setImageLoading(false)}
                    style={{ display: imageLoading ? 'none' : 'block' }}
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {roomName}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {roomDetail}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );

    const roomModal = (
        <Dialog
            open={openCard}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseCard}
            fullWidth
            maxWidth="lg"
        >
            <DialogTitle>{roomName}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Box sx={{ maxWidth: 1, flexGrow: 1 }}>
                            <Paper
                                square
                                elevation={0}
                                sx={{ display: "flex", alignItems: "center", height: 50, pl: 2, bgcolor: "background.default" }}
                            >
                                <Typography>{labels[activeStep]}</Typography>
                            </Paper>
                            {maxSteps > 1 && (
                                <Swiper
                                    onSlideChange={handleSlideChange}
                                    onSwiper={setSwiperInstance}
                                    initialSlide={activeStep}
                                    spaceBetween={10}
                                    slidesPerView={1}
                                    loop
                                >
                                    {images.map((step, index) => (
                                        <SwiperSlide key={step}>
                                            <ImagePopup
                                                src={step}
                                                fullResolution={step}
                                                alt={labels[activeStep] || "Image description"}
                                            />
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            )}
                            <MobileStepper
                                steps={maxSteps}
                                position="static"
                                activeStep={activeStep}
                                nextButton={
                                    <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                                        Next
                                        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                                    </Button>
                                }
                                backButton={
                                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                                        Back
                                    </Button>
                                }
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h6">{roomName}</Typography>
                        <Typography variant="body1">{roomDetail}</Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                {location.pathname === '/' ? goBook : book}
                <Button onClick={handleCloseCard}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );

    return (
        <>
            {roomCard}
            {roomModal}
        </>
    );
};

export default RoomCard;
