import React from 'react';
import { usePayMutation } from './paymentApiSlice'
import styles from './Checkout.module.css'

const Checkout = ({ roomName, amount, nights, reservationId, language }) => {

  const [Pay] = usePayMutation()

  const handlePay = async () => {
    const data = await Pay({ roomName, amount, nights, reservationId, language })
    window.location.replace(data?.data?.message);
  }

  return (
    <button onClick={handlePay} className={styles.button}>Pay</button>
  );
};

export default Checkout;
