import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetSessionMutation } from '../features/payment/paymentApiSlice';
import { PulseLoader } from 'react-spinners';
import { useGetPublicReservationsQuery } from '../components/publicReservationsApiSlice';
import { Button, Typography, Box, Grid, Alert } from '@mui/material';
import html2canvas from 'html2canvas'; // Import html2canvas for downloading as image
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

const PublicSuccess = () => {
    const effectRan = useRef(false);
    const navigate = useNavigate();
    const [reservationId, setReservationId] = useState(null);
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const sessionId = params.get('session_id');
    const [getSession] = useGetSessionMutation();
    const receiptRef = useRef(); // Create a ref for the receipt box
    const { t, i18n } = useTranslation(); // Get the translation function and i18n instance

    useEffect(() => {
        if (!effectRan.current) {
            const fetchSession = async () => {
                try {
                    const session = await getSession(sessionId);
                    if (session?.data?.message) {
                        setReservationId(session.data.message);
                    } else {
                        console.error('Session data is invalid');
                    }
                } catch (error) {
                    console.error('Failed to fetch session:', error);
                }
            };
            fetchSession();
        }
        return () => {
            effectRan.current = true;
            setReservationId(null);
        };
    }, [sessionId, getSession]);

    const goBack = () => navigate('/'); // Navigates to the root path

    // Get reservation details using the reservationId
    const { reservation, error } = useGetPublicReservationsQuery("reservationsList", {
        selectFromResult: ({ data }) => ({
            reservation: data?.entities[reservationId],
        }),
    });

    // Get hotel name from environment variable
    const hotelName = process.env.REACT_APP_HOTEL_NAME;

    // Function to format date based on the current language
    const formatDate = (date) =>
        new Date(date).toLocaleString(i18n.language, {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        });

    const downloadReceiptAsImage = () => {
        html2canvas(receiptRef.current).then((canvas) => {
            const link = document.createElement('a');
            link.href = canvas.toDataURL('image/jpeg'); // Convert the canvas to JPEG
            link.download = 'receipt.jpg'; // Set the file name
            link.click(); // Trigger the download
        });
    };

    let content;

    // Handle loading state
    if (!reservation && !error) {
        content = <PulseLoader color={"#FFF"} />;
    } else if (error || !reservation) {
        content = <Alert severity="error">{t('PublicSuccess.reservationLoad')}</Alert>;
    } else {
        const {
            guest: { name, email, phone, adults, kids },
            room,
            checkInDate,
            checkOutDate,
            paymentAmount,
            note,
        } = reservation;

        content = (
            <Box
                ref={receiptRef} // Attach the ref to the box for downloading
                sx={{
                    maxWidth: 600,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    borderRadius: '8px',
                    padding: '15px',
                    margin: '20px auto',
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                }}
            >
                <Typography
                    variant="h4"
                    sx={{
                        fontSize: '3vh',
                        fontWeight: 'bold',
                        fontStyle: 'italic',
                        border: '2px solid black', // Black border
                        borderRadius: '8px', // Rounded corners
                        padding: '10px',
                        textAlign: 'center', // Center the text
                        marginBottom: 2,
                        width: '96%', // Make it full width
                    }}
                >
                    {hotelName}
                </Typography>
                <Typography variant="h6" sx={{ marginBottom: 2 }}>
                    {t('PublicSuccess.title', { name, checkInDate: formatDate(checkInDate), checkOutDate: formatDate(checkOutDate) })}
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Typography><strong>{t('PublicSuccess.room')}</strong> {room}</Typography>
                        <Typography><strong>{t('PublicSuccess.guestName')}</strong> {name}</Typography>
                        <Typography><strong>{t('PublicSuccess.email')}</strong> {email}</Typography>
                        <Typography><strong>{t('PublicSuccess.phoneNumber')}</strong> {phone}</Typography>
                        <Typography><strong>{t('PublicSuccess.adults')}</strong> {adults}</Typography>
                        {kids > 0 && <Typography><strong>{kids > 1 ? t('PublicSuccess.children') : t('PublicSuccess.child')}</strong> {kids}</Typography>}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography><strong>{t('PublicSuccess.checkInDate')}</strong> {formatDate(checkInDate)}</Typography>
                        <Typography><strong>{t('PublicSuccess.checkOutDate')}</strong> {formatDate(checkOutDate)}</Typography>
                        <Typography><strong>{t('PublicSuccess.paymentAmount')}</strong> ฿{paymentAmount}</Typography>
                        <Typography><strong>{t('PublicSuccess.paymentStatus')}</strong> {t('PublicSuccess.paid')}</Typography>
                        {note && <Typography><strong>{t('PublicSuccess.note')}</strong> {note}</Typography>}
                    </Grid>
                </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', width: '100%' }}>
                    <Button variant="outlined" onClick={goBack}>{t('PublicSuccess.returnButton')}</Button>
                    <Button variant="contained" onClick={downloadReceiptAsImage}>{t('PublicSuccess.downloadReceiptButton')}</Button>
                </Box>
            </Box>
        );
    }

    return content;
};

export default PublicSuccess;
