import React from 'react';
import { Outlet } from 'react-router-dom';
import PublicHeader from './PublicHeader';
import PublicFooter from './PublicFooter';
import styles from './PublicLayout.module.css';

const PublicLayout = () => {
    return (
        <div className={styles.layoutContainer}>
            <PublicHeader />
            <div className={styles.publicContainer}>
                <Outlet />
            </div>
            <PublicFooter className={styles.footer} />
        </div>
    );
};

export default PublicLayout;