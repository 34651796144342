import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetSessionMutation } from '../payment/paymentApiSlice';
import { PulseLoader } from 'react-spinners';
import { useGetReservationsQuery } from '../reservations/reservationsApiSlice';
import { Button, Typography, Box, Grid, Alert } from '@mui/material';
import html2canvas from 'html2canvas'; // Import html2canvas for downloading as image

const PublicSuccess = () => {
  const effectRan = useRef(false);
  const navigate = useNavigate();
  const [reservationId, setReservationId] = useState(null);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const sessionId = params.get('session_id');
  const [getSession] = useGetSessionMutation();
  const receiptRef = useRef(); // Create a ref for the receipt box

  useEffect(() => {
    if (!effectRan.current) {
      const fetchSession = async () => {
        try {
          const session = await getSession(sessionId);
          if (session?.data?.message) {
            setReservationId(session.data.message);
          } else {
            console.error('Session data is invalid');
          }
        } catch (error) {
          console.error('Failed to fetch session:', error);
        }
      };
      fetchSession();
    }
    return () => {
      effectRan.current = true;
      setReservationId(null);
    };
  }, [sessionId, getSession]);

  const goBack = () => navigate('/dash/reservations'); // Navigates to the root path

  // Get reservation details using the reservationId
  const { reservation, error } = useGetReservationsQuery("reservationsList", {
    selectFromResult: ({ data }) => ({
      reservation: data?.entities[reservationId],
    }),
  });

  // Get hotel name from environment variable
  const hotelName = process.env.REACT_APP_HOTEL_NAME;

  let content;

  // Handle loading state
  if (!reservation && !error) {
    content = <PulseLoader color={"#FFF"} />;
  } else if (error || !reservation) {
    content = <Alert severity="error">Failed to load reservation details.</Alert>;
  } else {
    const {
      guest: { name, email, phone, adults, kids },
      room,
      checkInDate,
      checkOutDate,
      paymentAmount,
      note,
    } = reservation;

    const formatDate = (date) =>
      new Date(date).toLocaleString('en-US', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      });

    const renderLabelValue = (label, value) => (
      <Box display="flex" flexDirection="column" marginBottom={1}>
        <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '2.7vh' }}>{label}</Typography>
        <Typography variant="body1" sx={{ fontSize: '2.5vh' }}>{value}</Typography>
      </Box>
    );

    const downloadReceiptAsImage = () => {
      html2canvas(receiptRef.current).then((canvas) => {
        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/jpeg'); // Convert the canvas to JPEG
        link.download = 'receipt.jpg'; // Set the file name
        link.click(); // Trigger the download
      });
    };

    content = (
      <Box
        ref={receiptRef} // Attach the ref to the box for downloading
        sx={{
          maxWidth: 600,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          borderRadius: '8px',
          padding: '15px',
          margin: '20px auto',
          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontSize: '3vh',
            fontWeight: 'bold',
            fontStyle: 'italic',
            border: '2px solid black', // Black border
            borderRadius: '8px', // Rounded corners
            padding: '10px',
            textAlign: 'center', // Center the text
            marginBottom: 2,
            width: '96%', // Make it full width
          }}
        >
          {hotelName}
        </Typography>
        <Typography variant="h4" sx={{ fontSize: '3vh', mb: 2 }}>
          A reservation for {name} has been created on {formatDate(checkInDate)}, checking out on {formatDate(checkOutDate)}.
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            {renderLabelValue('Room:', room)}
            {renderLabelValue('Guest Name:', name)}
            {renderLabelValue('Email:', email)}
            {renderLabelValue('Phone Number:', phone)}
            {renderLabelValue('Adults:', adults)}
            {kids > 0 && renderLabelValue(kids > 1 ? 'Children:' : 'Child:', kids)}
          </Grid>
          <Grid item xs={12} md={6}>
            {renderLabelValue('Check-in Date:', formatDate(checkInDate))}
            {renderLabelValue('Check-out Date:', formatDate(checkOutDate))}
            {renderLabelValue('Payment Amount:', `฿${paymentAmount}`)}
            {renderLabelValue('Payment Status:', 'Paid')}
            {note && renderLabelValue('Note:', note)}
          </Grid>
        </Grid>
        <Box sx={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button
            variant="contained"
            onClick={goBack} // Calls the goBack function on click
            sx={{ backgroundColor: '#FF5733', color: '#FFF', padding: '10px 20px' }}
          >
            Return
          </Button>
          <Button
            variant="contained"
            onClick={downloadReceiptAsImage} // Call to download the receipt
            sx={{ backgroundColor: '#007BFF', color: '#FFF', padding: '10px 20px' }}
          >
            Download Receipt
          </Button>
        </Box>
      </Box>
    );
  }

  return content;
};

export default PublicSuccess;
