import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import DatePicker from 'react-datepicker'; // Import the DatePicker
import "react-datepicker/dist/react-datepicker.css"; // Import styles
import { TextField, Button, Grid, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

const InformationForm = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { t } = useTranslation(); // Access the translation function

    const [roomName] = useState(state.roomName);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [adults, setAdults] = useState(1);
    const [kids, setKids] = useState(0);
    const [checkInDate, setCheckInDate] = useState(state.checkInDate || null);
    const [checkOutDate, setCheckOutDate] = useState(state.checkOutDate || null);
    const [note, setNote] = useState('');
    const [errors, setErrors] = useState({});

    useEffect(() => {
        setCheckInDate(state.checkInDate || null);
        setCheckOutDate(state.checkOutDate || null);
    }, [state.checkInDate, state.checkOutDate]);

    const handleInputChange = (setter) => (e) => setter(e.target.value);

    const today = new Date();
    const tomorrow = new Date(checkInDate || today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    const validateInputs = () => {
        const newErrors = {};
        if (!name) newErrors.name = t("InformationForm.validation.nameRequired");
        if (!email) newErrors.email = t("InformationForm.validation.emailRequired");
        if (!phone) newErrors.phone = t("InformationForm.validation.phoneRequired");
        if (!checkInDate) newErrors.checkInDate = t("InformationForm.validation.checkInDateRequired");
        if (!checkOutDate) newErrors.checkOutDate = t("InformationForm.validation.checkOutDateRequired");
        return newErrors;
    };

    const canSave = [name, email, phone, adults, checkInDate, checkOutDate].every(Boolean);

    const handleContinueClicked = () => {
        const validationErrors = validateInputs();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        navigate('/book/payment', {
            state: {
                roomName,
                roomPrice: state.roomPrice,
                name,
                email,
                phone,
                adults,
                kids,
                checkInDate,
                checkOutDate,
                note
            }
        });
    };

    const handleBackClicked = () => navigate(-1);

    return (
        <Box sx={{
            maxWidth: 600,
            margin: '50px auto',
            padding: 3,
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            borderRadius: 2,
            boxShadow: 3
        }}>
            <Typography variant="h5" gutterBottom>
                {t('InformationForm.reservationInfo.title')}
            </Typography>

            <Typography variant="h6">
                {t('InformationForm.reservationInfo.room')}: {roomName}
            </Typography>
            <Typography variant="subtitle1">
                {t('InformationForm.reservationInfo.roomPrice')}: {state.roomPrice} {t('InformationForm.reservationInfo.currency.perNight')}
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label={t('InformationForm.form.name')}
                        variant="outlined"
                        value={name}
                        onChange={handleInputChange(setName)}
                        error={!!errors.name}
                        helperText={errors.name}
                        sx={{
                            backgroundColor: 'white',
                            borderRadius: 2,
                            '& .MuiOutlinedInput-root': {
                                borderRadius: 2,
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label={t('InformationForm.form.email')}
                        variant="outlined"
                        type="email"
                        value={email}
                        onChange={handleInputChange(setEmail)}
                        error={!!errors.email}
                        helperText={errors.email}
                        sx={{
                            backgroundColor: 'white',
                            borderRadius: 2,
                            '& .MuiOutlinedInput-root': {
                                borderRadius: 2,
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label={t('InformationForm.form.phone')}
                        variant="outlined"
                        type="tel"
                        value={phone}
                        onChange={handleInputChange(setPhone)}
                        error={!!errors.phone}
                        helperText={errors.phone}
                        sx={{
                            backgroundColor: 'white',
                            borderRadius: 2,
                            '& .MuiOutlinedInput-root': {
                                borderRadius: 2,
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label={t('InformationForm.form.adults')}
                        variant="outlined"
                        type="number"
                        value={adults}
                        onChange={handleInputChange(setAdults)}
                        inputProps={{ min: 1 }}
                        sx={{
                            backgroundColor: 'white',
                            borderRadius: 2,
                            '& .MuiOutlinedInput-root': {
                                borderRadius: 2,
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label={t('InformationForm.form.children')}
                        variant="outlined"
                        type="number"
                        value={kids}
                        onChange={handleInputChange(setKids)}
                        inputProps={{ min: 0 }}
                        sx={{
                            backgroundColor: 'white',
                            borderRadius: 2,
                            '& .MuiOutlinedInput-root': {
                                borderRadius: 2,
                            }
                        }}
                    />
                </Grid>

                {/* Date Pickers for Check In and Check Out */}
                <Grid item xs={12}>
                    <DatePicker
                        selectsRange
                        startDate={checkInDate}
                        endDate={checkOutDate}
                        onChange={(update) => {
                            setCheckInDate(update[0]);
                            setCheckOutDate(update[1]);
                        }}
                        placeholderText={t('InformationForm.form.selectDate')}
                        wrapperClassName="date-picker" // Custom class for styling
                        minDate={today}
                        customInput={
                            <TextField
                                fullWidth
                                label={t('InformationForm.form.checkInCheckOut')}
                                error={!!errors.checkInDate || !!errors.checkOutDate}
                                helperText={errors.checkInDate || errors.checkOutDate}
                                sx={{
                                    backgroundColor: 'white',
                                    borderRadius: 2,
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: 2,
                                    }
                                }}
                            />
                        }
                    />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label={t('InformationForm.form.note')}
                        variant="outlined"
                        value={note}
                        onChange={handleInputChange(setNote)}
                        multiline
                        rows={3}
                        sx={{
                            backgroundColor: 'white',
                            borderRadius: 2,
                            '& .MuiOutlinedInput-root': {
                                borderRadius: 2,
                            }
                        }}
                    />
                </Grid>
            </Grid>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleBackClicked}
                >
                    {t('InformationForm.button.back')}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={!canSave}
                    onClick={handleContinueClicked}
                >
                    {t('InformationForm.button.continue')}
                </Button>
            </Box>
        </Box>
    );
};

export default InformationForm;
