import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useGetTransactionsQuery } from "./transactionsApiSlice";
import { useGetReservationsQuery } from "../reservations/reservationsApiSlice";
import { memo } from "react";
import { TableCell, TableRow, IconButton, Typography } from "@mui/material";

const Transaction = ({ transactionId }) => {
    const { transaction } = useGetTransactionsQuery("transactionsList", {
        selectFromResult: ({ data }) => ({
            transaction: data?.entities[transactionId],
        }),
    });

    const { data: reservations, isSuccess } = useGetReservationsQuery();

    let matchedReservation;

    if (isSuccess) {
        const { ids } = reservations;

        const matchedReservationId = ids.filter(
            (id) => reservations?.entities[id]?.id === transaction?.reservationId
        );

        matchedReservation = reservations?.entities[matchedReservationId];
    }

    const navigate = useNavigate();

    if (transaction && isSuccess) {
        const handleEdit = () => navigate(`/dash/transactions/${transactionId}`);

        return (
            <TableRow>
                <TableCell>
                    <Typography variant="body1">
                        Payment for: {matchedReservation?.guest?.name || "Unknown"}
                    </Typography>
                </TableCell>
                <TableCell>
                    <Typography variant="body1">${transaction.amount}</Typography>
                </TableCell>
                <TableCell>
                    <IconButton onClick={handleEdit} color="primary">
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </IconButton>
                </TableCell>
            </TableRow>
        );
    } else return null;
};

const memoizedTransaction = memo(Transaction);

export default memoizedTransaction;
