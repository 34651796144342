import { useState, useEffect } from "react";
import { useUpdateUserMutation, useDeleteUserMutation } from "./usersApiSlice";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTrashCan, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { ROLES } from "../../config/roles";
import {
    Box,
    Typography,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Alert,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";

const USER_REGEX = /^[A-z]{3,20}$/;
const PWD_REGEX = /^[A-z0-9!@#$%]{4,12}$/;

const EditUserForm = ({ user }) => {
    const [updateUser, { isLoading, isSuccess, isError, error }] =
        useUpdateUserMutation();
    const [deleteUser, { isSuccess: isDelSuccess, isError: isDelError, error: delerror }] =
        useDeleteUserMutation();

    const navigate = useNavigate();

    const [username, setUsername] = useState(user.username);
    const [validUsername, setValidUsername] = useState(false);
    const [password, setPassword] = useState("");
    const [validPassword, setValidPassword] = useState(false);
    const [roles, setRoles] = useState(user.roles);

    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);

    useEffect(() => {
        setValidUsername(USER_REGEX.test(username));
    }, [username]);

    useEffect(() => {
        setValidPassword(PWD_REGEX.test(password));
    }, [password]);

    useEffect(() => {
        if (isSuccess || isDelSuccess) {
            setUsername("");
            setPassword("");
            setRoles([]);
            navigate("/dash/users");
        }
    }, [isSuccess, isDelSuccess, navigate]);

    const onUsernameChanged = (e) => setUsername(e.target.value);
    const onPasswordChanged = (e) => setPassword(e.target.value);
    const onRolesChanged = (e) => setRoles(e.target.value);

    const onSaveUserClicked = async () => {
        if (password) {
            await updateUser({ id: user.id, username, password, roles });
        } else {
            await updateUser({ id: user.id, username, roles });
        }
    };

    const onDeleteUserConfirmed = async () => {
        await deleteUser({ id: user.id });
        setDeleteDialogOpen(false);
    };

    const options = Object.values(ROLES).map((role) => (
        <MenuItem key={role} value={role}>
            {role}
        </MenuItem>
    ));

    let canSave;
    if (password) {
        canSave = [roles.length, validUsername, validPassword].every(Boolean) && !isLoading;
    } else {
        canSave = [roles.length, validUsername].every(Boolean) && !isLoading;
    }

    return (
        <Box
            sx={{
                maxWidth: 600,
                mx: "auto",
                p: 3,
                bgcolor: "background.paper",
                boxShadow: 3,
                borderRadius: 2,
            }}
        >
            {(isError || isDelError) && (
                <Alert severity="error" sx={{ mb: 2 }}>
                    {(error?.data?.message || delerror?.data?.message) ?? ""}
                </Alert>
            )}

            <Typography variant="h4" gutterBottom>
                Edit User
            </Typography>

            <form onSubmit={(e) => e.preventDefault()}>
                <TextField
                    label="Username (3-20 letters)"
                    variant="outlined"
                    fullWidth
                    sx={{ mb: 2 }}
                    value={username}
                    onChange={onUsernameChanged}
                    error={!validUsername && username !== ""}
                    helperText={
                        !validUsername && username !== "" && "Invalid username format"
                    }
                    required
                />

                <TextField
                    label="Password (leave blank for no change)"
                    type="password"
                    variant="outlined"
                    fullWidth
                    sx={{ mb: 2 }}
                    value={password}
                    onChange={onPasswordChanged}
                    error={password && !validPassword}
                    helperText={
                        password &&
                        !validPassword &&
                        "Invalid password format (4-12 chars incl. !@#$%)"
                    }
                />

                <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
                    <InputLabel>Assigned Roles</InputLabel>
                    <Select
                        multiple
                        value={roles}
                        onChange={onRolesChanged}
                        label="Assigned Roles"
                        renderValue={(selected) => selected.join(", ")}
                        error={!roles.length}
                    >
                        {options}
                    </Select>
                </FormControl>

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => navigate(-1)}  // Navigate back to the previous page
                        startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
                    >
                        Back
                    </Button>

                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => setDeleteDialogOpen(true)}
                        startIcon={<FontAwesomeIcon icon={faTrashCan} />}
                    >
                        Delete
                    </Button>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onSaveUserClicked}
                        disabled={!canSave}
                        startIcon={
                            isLoading ? <CircularProgress size={20} /> : <FontAwesomeIcon icon={faSave} />
                        }
                    >
                        Save
                    </Button>
                </Box>
            </form>

            {/* Confirmation Dialog */}
            <Dialog
                open={isDeleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
            >
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this user? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={onDeleteUserConfirmed}
                        color="error"
                        variant="contained"
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default EditUserForm;
