import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from 'react-router-dom'
import { useGetRoomsQuery } from './roomsApiSlice'
import { memo } from 'react'
import MyCalendar from '../calendar/Calendar'
import { Box, Grid, Typography, Button } from "@mui/material"

const Room = ({ roomId }) => {
    const { room } = useGetRoomsQuery("roomsList", {
        selectFromResult: ({ data }) => ({
            room: data?.entities[roomId]
        }),
    })

    const navigate = useNavigate()

    if (room) {
        const handleEdit = () => navigate(`/dash/rooms/${roomId}`)

        const datesOccupiedCalendar = <MyCalendar dateOccupiedObjects={room.datesOccupied} />

        const roomImages = (
            <Grid container spacing={2}>
                {room.roomImages.map((image, index) => (
                    <Grid item xs={12} md={6} lg={3} key={index}>
                        <label>{room.imageLabels[index]}</label>
                        <img src={image} alt={room.imageLabels[index]} style={{ width: "100%", height: "auto", borderRadius: "8px" }} />
                    </Grid>
                ))}
            </Grid>
        )

        return (
            <Box sx={{
                maxWidth: 800,
                mx: "auto",
                p: 3,
                bgcolor: "background.paper",
                boxShadow: 3,
                borderRadius: 2,
                color: 'black'
            }}>
                <Typography variant="h4" gutterBottom>Room Name: {room.roomName}</Typography>
                <Typography variant="h6">Price: ${room.roomPrice}</Typography>
                <Typography variant="body1" gutterBottom>Description:</Typography>
                <Typography variant="body2" paragraph>{room?.roomDescription}</Typography>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleEdit}
                    startIcon={<FontAwesomeIcon icon={faPenToSquare} />}
                    sx={{ marginBottom: 2 }}
                >
                    Edit Room
                </Button>

                <Typography variant="body1" gutterBottom>Dates occupied:</Typography>
                {datesOccupiedCalendar}

                <Typography variant="body1" gutterBottom>Room Images:</Typography>
                {roomImages}
            </Box>
        )
    }

    return null
}

const memoizedRoom = memo(Room)

export default memoizedRoom
