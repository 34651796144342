import React from 'react'
import styles from './PublicFooter.module.css'

const PublicFooter = () => {

    const year = new Date().getFullYear();
    // Get hotel name from environment variable
    const hotelName = process.env.REACT_APP_HOTEL_NAME;

    return (
        <footer className={styles.footer}>
            <p>© {year} {hotelName}</p>
        </footer>
    )
}

export default PublicFooter