import React from 'react';
import { useGetPublicRoomsQuery } from './publicRoomsApiSlice';
import RoomCard from './RoomCard';
import { Grid } from "@mui/material";
import PulseLoader from 'react-spinners/PulseLoader';
import { useTranslation } from 'react-i18next'; // Importing i18next hook
import styles from './RoomSection.module.css';

const RoomSection = ({ className }) => {
    const { t } = useTranslation(); // Hook to access translation function

    const { data: rooms, isLoading, isError, error, isSuccess } = useGetPublicRoomsQuery("roomsList", {
        pollingInterval: 5000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    });

    let roomsSection;

    if (isLoading) {
        roomsSection = <PulseLoader />;
    } else if (isError) {
        roomsSection = <p>{t('RoomSection.Error')}: {error?.data?.message || t('RoomSection.An error occurred')}</p>;
    } else if (isSuccess) {
        const roomObjects = rooms?.ids.map(id => rooms?.entities[id]);

        roomsSection = (
            <Grid
                container
                spacing={2}
                justifyContent={roomObjects.length <= 2 ? "center" : "flex-start"} // Center if 1 or 2 rooms
            >
                {roomObjects.length > 0 ? (
                    roomObjects.map(room => (
                        <Grid key={room.id} item xs={12} md={6} lg={4} className={styles.gridItem}>
                            <RoomCard roomId={room.id} />
                        </Grid>
                    ))
                ) : (
                    <h1>{t('RoomSection.No Rooms')}</h1> // Translated message when no rooms are available
                )}
            </Grid>
        );
    }

    return (
        <div className={className}>
            {roomsSection}
        </div>
    );
}

export default RoomSection;
