import { useRef, useState, useEffect } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { setCredentials } from './authSlice';
import { useLoginMutation } from './authApiSlice';

import usePersist from '../../hooks/usePersist';

// Material-UI components
import {
    Container,
    Typography,
    TextField,
    Button,
    Checkbox,
    FormControlLabel,
    CircularProgress,
    Alert
} from '@mui/material';

import { styled } from '@mui/material/styles';

// Styles
const LoginContainer = styled(Container)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: theme.palette.background.default,
}));

const LoginForm = styled('form')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 400,
    padding: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    backgroundColor: theme.palette.background.paper,
}));

const Login = () => {
    const userRef = useRef(null);
    const errRef = useRef(null);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [persist, setPersist] = usePersist();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [login, { isLoading }] = useLoginMutation();

    useEffect(() => {
        if (userRef.current) {
            userRef.current.focus(); // Ensure the reference is defined before calling focus
        }
    }, []);

    useEffect(() => {
        setErrMsg('');
    }, [username, password]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const { accessToken } = await login({ username, password }).unwrap();
            dispatch(setCredentials({ accessToken }));
            setUsername('');
            setPassword('');
            navigate('/dash');
        } catch (err) {
            if (!err.status) {
                setErrMsg('No Server Response');
            } else if (err.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.status === 401) {
                setErrMsg('Unauthorized. Check your credentials.');
            } else {
                setErrMsg('An unexpected error occurred.');
            }
            if (errRef.current) {
                errRef.current.focus();
            }
        }
    };

    return (
        <LoginContainer maxWidth="sm">
            <Typography variant="h4" component="h1" gutterBottom>
                Employee Login
            </Typography>
            {errMsg && (
                <Alert severity="error" ref={errRef} sx={{ mb: 2 }}>
                    {errMsg}
                </Alert>
            )}
            <LoginForm onSubmit={handleSubmit}>
                <TextField
                    label="Username"
                    variant="outlined"
                    fullWidth
                    inputRef={userRef} // Correctly attach the ref
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    autoComplete="off"
                    required
                    margin="normal"
                />
                <TextField
                    label="Password"
                    variant="outlined"
                    fullWidth
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    margin="normal"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={persist}
                            onChange={() => setPersist((prev) => !prev)}
                        />
                    }
                    label="Trust This Device"
                />
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mt: 2 }}
                    disabled={isLoading}
                >
                    {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Sign In'}
                </Button>
            </LoginForm>
            <Button
                component={RouterLink}
                to="/"
                sx={{ mt: 2 }}
                variant="text"
                color="secondary"
            >
                Back to Home
            </Button>
        </LoginContainer>
    );
};

export default Login;
