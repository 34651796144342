import { useParams } from 'react-router-dom'
import EditRoomForm from './EditRoomForm'
import { useGetRoomsQuery } from './roomsApiSlice'
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../hooks/useTitle'
import useAuth from '../../hooks/useAuth'

const EditRoom = () => {
    useTitle('Edit Room')

    const { id } = useParams()

    const { username, isManager, isAdmin } = useAuth()

    const { room } = useGetRoomsQuery("roomsList", {
        selectFromResult: ({ data }) => ({
            room: data?.entities[id]
        }),
    })

    if (!room) return <PulseLoader color={"#FFF"} />

    if (!isManager && !isAdmin) {
        if (room.roomName !== username) {
            return <p className="errmsg">No access</p>
        }
    }

    const content = <EditRoomForm room={room} />

    return content
}
export default EditRoom