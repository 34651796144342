import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faHouseChimneyMedical,
    faHouseUser,
    faCalendarPlus,
    faUserPlus,
    faCalendar,
    faUser,
    faRightFromBracket,
    faBahtSign,
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useSendLogoutMutation } from '../features/auth/authApiSlice';
import useAuth from '../hooks/useAuth';
import { Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next'; // Import from react-i18next

const RESERVATIONS_REGEX = /^\/dash\/reservations(\/)?$/;
const ROOMS_REGEX = /^\/dash\/rooms(\/)?$/;
const USERS_REGEX = /^\/dash\/users(\/)?$/;
const TRANSACTIONS_REGEX = /^\/dash\/transactions(\/)?$/;

const DashHeader = () => {
    const { isManager, isAdmin } = useAuth();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const [sendLogout, { isSuccess, error }] = useSendLogoutMutation();
    const { t, i18n } = useTranslation(); // Translation hook

    useEffect(() => {
        if (isSuccess) navigate('/');
    }, [isSuccess, navigate]);

    // Language toggle handler
    const toggleLanguage = () => {
        const newLang = i18n.language === 'en' ? 'th' : 'en'; // Switch between 'en' and 'th'
        i18n.changeLanguage(newLang);
    };

    const buttonConfigs = [
        {
            condition: RESERVATIONS_REGEX.test(pathname),
            icon: faCalendarPlus,
            title: 'New Reservation',
            onClick: () => navigate('/dash/reservations/new'),
        },
        {
            condition: !RESERVATIONS_REGEX.test(pathname) && pathname.includes('/dash'),
            icon: faCalendar,
            title: 'Reservations',
            onClick: () => navigate('/dash/reservations'),
        },
        {
            condition: ROOMS_REGEX.test(pathname),
            icon: faHouseChimneyMedical,
            title: 'New Room',
            onClick: () => navigate('/dash/rooms/new'),
        },
        {
            condition: !ROOMS_REGEX.test(pathname) && pathname.includes('/dash'),
            icon: faHouseUser,
            title: 'Rooms',
            onClick: () => navigate('/dash/rooms'),
        },
        {
            condition: USERS_REGEX.test(pathname),
            icon: faUserPlus,
            title: 'New User',
            onClick: () => navigate('/dash/users/new'),
        },
        {
            condition: !USERS_REGEX.test(pathname) && pathname.includes('/dash') && (isManager || isAdmin),
            icon: faUser,
            title: 'Users',
            onClick: () => navigate('/dash/users'),
        },
        {
            condition: !TRANSACTIONS_REGEX.test(pathname) && pathname.includes('/dash'),
            icon: faBahtSign,
            title: 'Transactions',
            onClick: () => navigate('/dash/transactions'),
        },
    ];

    const logoutButton = (
        <Button variant="contained" color="secondary" onClick={sendLogout}>
            <FontAwesomeIcon icon={faRightFromBracket} /> Logout
        </Button>
    );

    // Language toggle button
    const languageToggle = (
        <Button
            aria-label="Toggle language"
            color="inherit"
            variant='contained'
            onClick={toggleLanguage}
            sx={{ mx: 1 }}
        >
            {i18n.language === 'en' ? 'ไทย' : 'EN'}
        </Button>
    );

    const buttonContent = buttonConfigs
        .filter(config => config.condition)
        .map((config, index) => (
            <Grid item xs={6} sm={6} md={3} key={index}>
                <Button
                    variant="contained"
                    onClick={config.onClick}
                    startIcon={<FontAwesomeIcon icon={config.icon} style={{ marginRight: '8px' }} />} // Adjust icon margin if needed
                    fullWidth
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        height: '64px',
                        fontSize: 'clamp(1rem, 2.5vw, 1.5rem)', // Responsive font size
                        lineHeight: 1.2,
                    }}
                >
                    <span style={{ flexGrow: 1, textAlign: 'center' }}>{config.title}</span>
                </Button>
            </Grid>
        ));

    return (
        <header >
            {/* Flex container for Home, Language toggle, and Logout */}
            <Grid container alignItems="center" spacing={1}>
                <Grid item xs={4}>
                    <Typography variant="h4" component={Link} to="/dash" sx={{ color: 'white' }} >
                        Home
                    </Typography>
                </Grid>
                {/* Language toggle in the center */}
                <Grid item xs={4} container justifyContent="center">
                    {languageToggle}
                </Grid>
                <Grid item xs={4} container justifyContent="flex-end">
                    {logoutButton}
                </Grid>
            </Grid>

            <p>{error?.data?.message}</p>

            <Grid container spacing={1} justifyContent="flex-start">
                {buttonContent}
            </Grid>
        </header>
    );
};

export default DashHeader;
