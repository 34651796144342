import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import { Box } from '@mui/material';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';
import styles from './Carousel.module.css';

// Use Picsum for random images
const carouselImages = [
    'https://picsum.photos/600/400?random=1',
    'https://picsum.photos/600/400?random=2',
    'https://picsum.photos/600/400?random=3',
];

const Carousel = () => {
    return (
        <Box className={styles.carouselContainer}>
            <Swiper
                modules={[Autoplay, Pagination]}
                autoplay={{ delay: 3000, disableOnInteraction: false }}
                pagination={{ clickable: true }}
                loop={true}
            >
                {carouselImages.map((src, index) => (
                    <SwiperSlide key={index}>
                        <div className={styles.carouselItem}>
                            <img src={src} alt={`carousel-img-${index}`} className={styles.image} />
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </Box>
    );
};

export default Carousel;
