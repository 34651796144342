import useAuth from '../../hooks/useAuth';
import { Grid } from '@mui/material';
import style from './Welcome.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faHouseChimneyMedical,
    faHouseUser,
    faCalendarPlus,
    faUserPlus,
    faCalendar,
    faUser,
    faBahtSign,
    // faCartPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';

const Welcome = () => {
    const date = new Date();
    const today = new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'long' }).format(date);
    const { username, isManager, isAdmin } = useAuth();
    const navigate = useNavigate();

    // Button handlers
    const buttonHandlers = {
        newReservation: () => navigate('/dash/reservations/new'),
        newRoom: () => navigate('/dash/rooms/new'),
        newUser: () => navigate('/dash/users/new'),
        reservations: () => navigate('/dash/reservations'),
        rooms: () => navigate('/dash/rooms'),
        users: () => navigate('/dash/users'),
        transactions: () => navigate('/dash/transactions'),
    };

    // Button components with max height and width
    const renderButton = (title, icon, label, handler) => (
        <button
            title={title}
            onClick={handler}
            aria-label={label}
            className={style.button} // Use CSS module for styling
        >
            <FontAwesomeIcon icon={icon} className={style.icon} /> {/* Add icon class */}
            <p style={{ textAlign: 'center', flex: 1, fontSize: 'clamp(1rem, 3.5vw, 1.5rem)' }}>{label}</p> {/* Center text in the button */}
        </button>
    );

    const isAuthorized = isManager || isAdmin;

    const buttonHeight = '14vh'; // Adjust this height as needed to match the link height

    const content = (
        <section style={{ color: 'white' }}>
            <p>{today}</p>
            <h1>Welcome {username}!</h1>
            <Grid container spacing={1} sx={{ m: '0', justifyContent: 'center' }}>
                <Grid item xs={12} lg={8}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <div style={{ height: buttonHeight }}>
                                {renderButton("Reservations", faCalendar, "Reservations", buttonHandlers.reservations)}
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div style={{ height: buttonHeight }}>
                                {renderButton("New Reservation", faCalendarPlus, "New Reservation", buttonHandlers.newReservation)}
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <div style={{ height: buttonHeight }}>
                                {renderButton("Rooms", faHouseUser, "Rooms", buttonHandlers.rooms)}
                            </div>
                        </Grid>
                        {isAuthorized && (
                            <Grid item xs={6}>
                                <div style={{ height: buttonHeight }}>
                                    {renderButton("New Room", faHouseChimneyMedical, "New Room", buttonHandlers.newRoom)}
                                </div>
                            </Grid>
                        )}
                    </Grid>
                    {isAuthorized && (

                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <div style={{ height: buttonHeight }}>
                                    {renderButton("Users", faUser, "Users", buttonHandlers.users)}
                                </div>
                            </Grid>

                            <Grid item xs={6}>
                                <div style={{ height: buttonHeight }}>
                                    {renderButton("New User", faUserPlus, "New User", buttonHandlers.newUser)}
                                </div>
                            </Grid>
                        </Grid>
                    )}

                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <div style={{ height: buttonHeight }}>
                                {renderButton("Transactions", faBahtSign, "Transactions", buttonHandlers.transactions)}
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </section>
    );

    return content;
};

export default Welcome;
