import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';
import { useGetReservationsQuery, useUpdateReservationMutation } from './reservationsApiSlice';
import { useGetTransactionsQuery } from '../transactions/transactionsApiSlice';
import { memo } from 'react';
import { useState, useEffect } from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import Grid from '@mui/system/Unstable_Grid/Grid';
import Checkout from '../payment/Checkout';
import styles from './Reservation.module.css';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Reservation = ({ reservationId }) => {
    const { reservation } = useGetReservationsQuery("reservationsList", {
        selectFromResult: ({ data }) => ({
            reservation: data?.entities[reservationId]
        }),
    });

    const navigate = useNavigate();
    const { i18n } = useTranslation();

    const daysBetween = (startDate, endDate) => {
        var millisecondsPerDay = 24 * 60 * 60 * 1000;
        return (treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerDay;
    };

    const [updateReservation, {
        isLoading,
        isError,
        error
    }] = useUpdateReservationMutation();

    useEffect(() => {
        setIsNotPaid(reservation?.paymentStatus === "paid");
    }, [reservation?.paymentStatus]);

    const [isNotPaid, setIsNotPaid] = useState(reservation?.paymentStatus === "paid");

    const treatAsUTC = (date) => {
        var result = new Date(date);
        result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
        return result;
    };

    const { data: transactions } = useGetTransactionsQuery();

    const findTransactionByReservationId = (reservationId) => {
        if (!transactions) return null;
        return Object.values(transactions.entities).find(
            (transaction) => transaction.reservationId === reservationId
        );
    };

    const foundTransaction = findTransactionByReservationId(reservation.id)
    let content;
    let paidUpdate;
    let reservationObject;

    if (reservation) {
        reservationObject = {
            id: reservation?.id,
            room: reservation?.room,
            name: reservation?.guest?.name,
            email: reservation?.guest?.email,
            phone: reservation?.guest?.phone,
            adults: reservation?.guest?.adults,
            kids: reservation?.guest?.kids,
            checkInDate: reservation?.checkInDate,
            checkOutDate: reservation?.checkOutDate,
            paymentStatus: reservation?.paymentStatus,
            paymentAmount: reservation?.paymentAmount,
            note: reservation?.note
        };

        const onPaidClicked = async () => {
            setIsNotPaid(!isNotPaid);
            if (!isNotPaid) {
                reservationObject.paymentStatus = "paid";
                await updateReservation(reservationObject);
            } else {
                reservationObject.paymentStatus = "pending";
                await updateReservation(reservationObject);
            }
        };

        const onTransactionClicked = () => {
            if (foundTransaction) navigate(`/dash/transactions/${foundTransaction?.id}`);
        };

        const handleEdit = () => navigate(`/dash/reservations/${reservationId}`);

        const nights = daysBetween(reservation?.checkInDate, reservation?.checkOutDate);

        const formatCheckInDate = new Date(reservation?.checkInDate).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric' });
        const formatCheckOutDate = new Date(reservation?.checkOutDate).toLocaleString('en-US', { day: 'numeric', month: 'long', year: 'numeric' });

        const paidClass = isNotPaid ? 'paid' : 'notPaid';

        const payButton = !isNotPaid ? <Checkout roomName={reservation?.room} amount={reservation?.paymentAmount} nights={nights} reservationId={reservation?.id} language={i18n.language} /> : null;

        const paidButton = <button className={styles.button} onClick={onPaidClicked}>{!isNotPaid ? "Paid" : "Not Paid"}</button>;

        const transactionButton = <button className={styles.button} onClick={onTransactionClicked}>{foundTransaction ? "Transaction" : "Paid Cash"}</button>;

        if (isLoading) paidUpdate = <PulseLoader color={"#FFF"} />;

        if (isError) paidUpdate = <p>{error}</p>;

        content = (
            <Box sx={{
                maxWidth: 1200,
                mx: "auto",
                p: 3,
                bgcolor: paidClass === 'paid' ? 'green' : 'darkred', color: 'white',
                boxShadow: 3,
                borderRadius: 2,
                marginTop: '10px'
            }}>
                {paidUpdate}
                <Grid container spacing={2} sx={{ backgroundColor: paidClass === 'paid' ? 'green' : 'darkred', color: 'white' }}>
                    <Grid item lg={6} md={6} xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                        <h2>{`${reservation?.guest?.name}`}</h2>
                        <button onClick={handleEdit} style={{ margin: '0 20px' }}>
                            <FontAwesomeIcon icon={faPenToSquare} size='2x' />
                        </button>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sx={{ display: 'flex', justifyContent: 'right' }}>
                        {paidButton}
                        {payButton}
                        {!isNotPaid ? null : transactionButton}
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    {/* Reservation Details */}
                    <Grid lg={3} md={4} xs={6} sx={{ padding: 1 }}>
                        <div>Check in date:</div><div>{formatCheckInDate}</div>
                    </Grid>
                    <Grid lg={3} md={4} xs={6} sx={{ padding: 1 }}>
                        <div>Check out date:</div><div>{formatCheckOutDate}</div>
                    </Grid>
                    <Grid lg={3} md={4} xs={6} sx={{ padding: 1 }}>
                        <div>Room:</div><div>{reservation?.room}</div>
                    </Grid>
                    <Grid lg={3} md={4} xs={6} sx={{ padding: 1 }}>
                        <div>Phone:</div><div>{reservation?.guest?.phone}</div>
                    </Grid>
                    <Grid lg={3} md={4} xs={6} sx={{ padding: 1 }}>
                        <div>Guests:</div><div>{`Adults:${reservation?.guest?.adults} Kids:${reservation?.guest?.kids}`}</div>
                    </Grid>
                    <Grid lg={3} md={4} xs={6} sx={{ padding: 1 }}>
                        <div>E-mail:</div><div>{reservation?.guest?.email}</div>
                    </Grid>
                    <Grid lg={3} md={4} xs={6} sx={{ padding: 1 }}>
                        <div>Payment status:</div><div>{reservation?.paymentStatus}</div>
                    </Grid>
                    <Grid lg={3} md={4} xs={6} sx={{ padding: 1 }}>
                        <div>Paid amount:</div><div>{reservation?.paymentAmount}</div>
                    </Grid>

                </Grid>
            </Box>
        );
    }

    return content;
};

const memoizedReservation = memo(Reservation);

export default memoizedReservation;
