import { useParams, useNavigate } from 'react-router-dom';
import EditTransactionForm from './EditTransactionForm';
import { useGetTransactionsQuery } from './transactionsApiSlice';
import useTitle from '../../hooks/useTitle';
import useAuth from '../../hooks/useAuth';
import { Box, Typography, Alert, CircularProgress, Paper, Button } from '@mui/material';

const EditTransaction = () => {
    useTitle('Edit Transaction');

    const { id } = useParams();
    const navigate = useNavigate();

    const { username, isManager, isAdmin } = useAuth();

    const { transaction } = useGetTransactionsQuery("transactionsList", {
        selectFromResult: ({ data }) => ({
            transaction: data?.entities[id],
        }),
    });

    if (!transaction) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                <CircularProgress size={40} />
            </Box>
        );
    }

    if (!isManager && !isAdmin) {
        if (transaction.transactionName !== username) {
            return (
                <Alert severity="error" sx={{ mt: 4 }}>
                    No access
                </Alert>
            );
        }
    }

    const content = <EditTransactionForm transaction={transaction} />;

    return (
        <Box sx={{ p: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Paper sx={{ p: 3, width: '100%', maxWidth: 600, boxShadow: 3, borderRadius: 2 }}>
                <Typography variant="h4" gutterBottom sx={{ textAlign: 'center' }}>
                    Edit Transaction
                </Typography>
                {content}
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', mt: 3 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => navigate(-1)}
                        sx={{ textTransform: 'none' }}
                    >
                        Back
                    </Button>
                </Box>
            </Paper>
        </Box>
    );
};

export default EditTransaction;
