import Carousel from '../components/Carousel'
import Highlights from '../components/Highlights'
import RoomSection from '../components/RoomSection'
import styles from './HomePage.module.css'
import HotelName from '../components/HotelName'

const HomePage = () => {

    return (
        <div className={styles.sections}>
            <HotelName className={styles.section} />
            <Carousel className={styles.section} />
            <Highlights className={styles.section} />
            <RoomSection className={styles.section} />
        </div>)

}

export default HomePage