import { useState, useEffect } from "react";
import { useUpdateReservationMutation, useDeleteReservationMutation } from "./reservationsApiSlice";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTrashCan, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import useAuth from "../../hooks/useAuth";
import { useGetRoomsQuery } from '../rooms/roomsApiSlice';
import { Box, Typography, TextField, FormControl, Select, MenuItem, Button, CircularProgress, Alert, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";


const EditReservationForm = ({ reservation }) => {
    const { isManager, isAdmin } = useAuth();
    const [updateReservation, { isLoading, isSuccess, isError, error }] = useUpdateReservationMutation();
    const [deleteReservation, { isSuccess: isDelSuccess, isError: isDelError, error: delerror }] = useDeleteReservationMutation();
    const { data: rooms } = useGetRoomsQuery('roomsList');
    const roomNames = rooms?.ids?.map(id => rooms?.entities[id]?.roomName) || [];
    const initialRoom = roomNames.includes(reservation.room) ? reservation.room : '';
    const navigate = useNavigate();
    const checkInDateString = new Date(reservation.checkInDate).toISOString().split("T")[0];
    const checkOutDateString = new Date(reservation.checkOutDate).toISOString().split("T")[0];

    const [room, setRoom] = useState(initialRoom);
    const [name, setName] = useState(reservation.guest.name);
    const [email, setEmail] = useState(reservation.guest.email);
    const [phone, setPhone] = useState(reservation.guest.phone);
    const [adults, setAdults] = useState(reservation.guest.adults);
    const [kids, setKids] = useState(reservation.guest.kids);
    const [checkInDate, setCheckInDate] = useState(checkInDateString);
    const [checkOutDate, setCheckOutDate] = useState(checkOutDateString);
    const [paymentStatus, setPaymentStatus] = useState(reservation.paymentStatus);
    const [paymentAmount, setPaymentAmount] = useState(reservation.paymentAmount);
    const [note, setNote] = useState(reservation.note);

    //Delete confirmation Dialog variables
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const handleDeleteDialogClose = () => setOpenDeleteDialog(false);
    const handleDeleteDialogOpen = () => setOpenDeleteDialog(true);

    useEffect(() => {
        if (isSuccess || isDelSuccess) {
            setRoom('');
            setName('');
            setEmail('');
            setPhone('');
            setAdults('');
            setKids('');
            setCheckInDate('');
            setCheckOutDate('');
            setPaymentStatus('');
            setPaymentAmount('');
            setNote('');
            navigate('/dash/reservations');
        }
    }, [isSuccess, isDelSuccess, navigate]);

    useEffect(() => {
        let price;
        rooms?.ids.forEach(id => {
            if (room === rooms?.entities[id]?.roomName) {
                price = rooms?.entities[id]?.roomPrice;
                setPaymentAmount(price);
            }
        });
    }, [room, rooms]);

    useEffect(() => {
        if (checkInDate && checkOutDate) {
            const checkIn = new Date(checkInDate);
            const checkOut = new Date(checkOutDate);
            const timeDifference = checkOut.getTime() - checkIn.getTime();
            const numberOfNights = timeDifference / (1000 * 3600 * 24); // Convert time difference to days
            let pricePerNight = 0;

            rooms?.ids.forEach(id => {
                if (room === rooms?.entities[id]?.roomName) {
                    pricePerNight = rooms?.entities[id]?.roomPrice;
                }
            });

            const calculatedPaymentAmount = numberOfNights * pricePerNight;
            setPaymentAmount(calculatedPaymentAmount);
        }
    }, [checkInDate, checkOutDate, room, rooms]);

    const onRoomChanged = (e) => {
        const selectedRoom = e.target.value;
        setRoom(selectedRoom);  // Set room as a string, not an array
    };

    const onNameChanged = (e) => setName(e.target.value);
    const onEmailChanged = (e) => setEmail(e.target.value);
    const onPhoneChanged = (e) => setPhone(e.target.value);
    const onAdultsChanged = (e) => setAdults(e.target.value);
    const onKidsChanged = (e) => setKids(e.target.value);
    const onCheckInDateChanged = (e) => setCheckInDate(e.target.value);
    const onCheckOutDateChanged = (e) => setCheckOutDate(e.target.value);
    const onPaymentStatusChanged = (e) => setPaymentStatus(e.target.value);
    const onPaymentAmountChanged = (e) => setPaymentAmount(e.target.value);
    const onNoteChanged = (e) => setNote(e.target.value);

    const canSave = [room, name, email, phone, adults, checkInDate, checkOutDate, paymentStatus, paymentAmount].every(Boolean) && !isLoading;

    const onSaveReservationClicked = async () => {
        if (canSave) {
            await updateReservation({ id: reservation.id, room, name, email, phone, adults, kids, checkInDate, checkOutDate, paymentStatus, paymentAmount, note });
        }
    };

    const onDeleteReservationClicked = async () => {
        await deleteReservation({ id: reservation.id });
        setOpenDeleteDialog(false); // Close dialog after delete
    };

    const today = new Date().toISOString().split("T")[0];
    const tomorrowDate = new Date(checkInDate ? checkInDate : today);
    tomorrowDate.setDate(tomorrowDate.getDate() + 1);
    const tomorrow = tomorrowDate.toISOString().split("T")[0];

    let deleteButton = null;
    if (isManager || isAdmin) {
        deleteButton = (
            <>
                {/* Delete Button */}
                <Button
                    variant="contained"
                    color="error"
                    onClick={handleDeleteDialogOpen}
                    startIcon={<FontAwesomeIcon icon={faTrashCan} />} // Use trash can icon
                >
                    Delete
                </Button>

                {/* Delete Confirmation Dialog */}
                <Dialog open={openDeleteDialog} onClose={handleDeleteDialogClose}>
                    <DialogTitle>Delete Reservation</DialogTitle>
                    <DialogContent>
                        <Typography>Are you sure you want to delete this reservation?</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDeleteDialogClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={onDeleteReservationClicked} color="error">
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }

    return (
        <Box sx={{
            maxWidth: 600,
            mx: "auto",
            p: 3,
            bgcolor: "background.paper",
            boxShadow: 3,
            borderRadius: 2,
        }}>
            <Typography variant="h5" gutterBottom>Edit Reservation for {reservation.guest.name}</Typography>

            {isError || isDelError ? <Alert severity="error">{error?.data?.message || delerror?.data?.message}</Alert> : null}

            <form noValidate>
                <FormControl fullWidth margin="normal">
                    <Typography variant="body1">Room</Typography>
                    <Select
                        value={room}
                        onChange={onRoomChanged}
                        fullWidth
                    >
                        {roomNames.length > 0 ? (
                            roomNames.map(item => (
                                <MenuItem key={item} value={item}>{item}</MenuItem>
                            ))
                        ) : (
                            <MenuItem disabled>No rooms available</MenuItem>
                        )}
                    </Select>
                </FormControl>

                <TextField
                    label="Name"
                    variant="outlined"
                    fullWidth
                    value={name}
                    onChange={onNameChanged}
                    margin="normal"
                    required
                />

                <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    value={email}
                    onChange={onEmailChanged}
                    margin="normal"
                    type="email"
                    required
                />

                <TextField
                    label="Phone"
                    variant="outlined"
                    fullWidth
                    value={phone}
                    onChange={onPhoneChanged}
                    margin="normal"
                    type="tel"
                    required
                />

                <TextField
                    label="Adults"
                    variant="outlined"
                    fullWidth
                    value={adults}
                    onChange={onAdultsChanged}
                    margin="normal"
                    type="number"
                    required
                />

                <TextField
                    label="Kids"
                    variant="outlined"
                    fullWidth
                    value={kids}
                    onChange={onKidsChanged}
                    margin="normal"
                    type="number"
                    required
                />

                <TextField
                    label="Check-in Date"
                    variant="outlined"
                    fullWidth
                    value={checkInDate}
                    onChange={onCheckInDateChanged}
                    margin="normal"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    required
                    inputProps={{ min: today }}
                />

                <TextField
                    label="Check-out Date"
                    variant="outlined"
                    fullWidth
                    value={checkOutDate}
                    onChange={onCheckOutDateChanged}
                    margin="normal"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    required
                    inputProps={{ min: tomorrow }}
                />

                <FormControl fullWidth margin="normal">
                    <Typography variant="body1">Payment Status</Typography>
                    <Select
                        value={paymentStatus}
                        onChange={onPaymentStatusChanged}
                        fullWidth
                    >
                        <MenuItem value="unpaid">Unpaid</MenuItem>
                        <MenuItem value="paid">Paid</MenuItem>
                        <MenuItem value="pending">Pending</MenuItem>
                    </Select>
                </FormControl>

                <TextField
                    label="Payment Amount"
                    variant="outlined"
                    fullWidth
                    value={paymentAmount}
                    onChange={onPaymentAmountChanged}
                    margin="normal"
                    type="number"
                    required
                />

                <TextField
                    label="Note"
                    variant="outlined"
                    fullWidth
                    value={note}
                    onChange={onNoteChanged}
                    margin="normal"
                    multiline
                    rows={4}
                />

                {isLoading ? (
                    <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: 2 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => navigate(-1)}  // Navigate back to the previous page
                            startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
                        >
                            Back
                        </Button>

                        {deleteButton}

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={onSaveReservationClicked}
                            disabled={!canSave}
                            startIcon={<FontAwesomeIcon icon={faSave} />}
                        >
                            Save Changes
                        </Button>
                    </Box>

                )}
            </form>
        </Box>
    );
};

export default EditReservationForm;
