import { useGetRoomsQuery } from "./roomsApiSlice";
import Room from './Room';
import PulseLoader from 'react-spinners/PulseLoader';
import { useState, useEffect, useMemo } from "react";
import AllCalendar from '../calendar/AllCalendar';
import { Box, Select, MenuItem, InputLabel, FormControl, Typography } from '@mui/material';
import { styled } from '@mui/system';
import styles from './RoomsList.module.css';

const RoomListContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',
    width: '100%',
    maxWidth: '100%',
    margin: '0 auto',
    color: 'white'
});

const FormControlStyled = styled(FormControl)({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    maxWidth: '300px',
    '& .MuiInputLabel-root': {
        color: 'white', // White text label
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'white', // White border
        },
        '&:hover fieldset': {
            borderColor: 'white', // White border on hover
        },
        '&.Mui-focused fieldset': {
            borderColor: 'white', // White border when focused
        },
    },
});

const RoomSelect = styled(Select)({
    width: '100%',
    marginTop: '8px',
    color: 'white', // White text in select box
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'white', // White border for select box
    },
    '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.1)', // Optional hover effect
    },
});

const ErrorMessage = styled(Typography)({
    color: 'red',
    fontWeight: 'bold',
});

const RoomList = () => {
    const [roomName, setRoomName] = useState('');
    const [roomId, setRoomId] = useState('');

    const onRoomNameChange = e => setRoomName(e.target.value);

    const {
        data: room,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetRoomsQuery('roomsList', {
        pollingInterval: 15000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    });

    useEffect(() => {
        const foundRoomId = room?.ids.find(id => room?.entities[id]?.roomName === roomName);
        setRoomId(foundRoomId || '');
    }, [roomName, room]);

    const roomNames = useMemo(() => {
        return room?.ids.map(id => room?.entities[id]?.roomName) || [];
    }, [room]);

    const options = useMemo(() => (
        roomNames.length > 0
            ? roomNames.map(name => (
                <MenuItem key={name} value={name}>{name}</MenuItem>
            ))
            : <MenuItem disabled>No rooms available</MenuItem>
    ), [roomNames]);

    let content;

    if (isLoading) content = <PulseLoader color="#FFF" />;

    if (isError) {
        content = <ErrorMessage className={styles.error}>{error?.data?.message || 'Error loading rooms'}</ErrorMessage>;
    }

    if (isSuccess) {
        content = (
            <RoomListContainer>
                <FormControlStyled>
                    <InputLabel htmlFor="reservation-room">Room</InputLabel>
                    <RoomSelect
                        id="reservation-room"
                        value={roomName}
                        onChange={onRoomNameChange}
                        label="Room"
                    >
                        <MenuItem value=''>--Select a room--</MenuItem>
                        {options}
                    </RoomSelect>
                </FormControlStyled>
                {roomId ? <Room key={roomId} roomId={roomId} /> : <AllCalendar />}
            </RoomListContainer>
        );
    }

    return content;
};

export default RoomList;
