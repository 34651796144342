import { useParams } from 'react-router-dom'
import EditUserForm from './EditUserForm'
import { useGetUsersQuery } from './usersApiSlice'
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../hooks/useTitle'
import useAuth from '../../hooks/useAuth'

const EditUser = () => {
    useTitle('Edit User')

    const { id } = useParams()

    const { username, isManager, isAdmin } = useAuth()

    const { user } = useGetUsersQuery("usersList", {
        selectFromResult: ({ data }) => ({
            user: data?.entities[id]
        }),
    })

    if (!user) return <PulseLoader color={"#FFF"} />

    if (!isManager && !isAdmin) {
        if (user.username !== username) {
            return <p className="errmsg">No access</p>
        }
    }

    const content = <EditUserForm user={user} />

    return content
}
export default EditUser