import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button"; // Import the Button component
import { BarLoader } from 'react-spinners'; // Import the BarLoader
import styles from "./ImagePopup.module.css"; // Import CSS module
import { useState } from "react";

function ImagePopup(props) {
    const [open, setOpen] = useState(false);
    const [loadingThumbnail, setLoadingThumbnail] = useState(true); // State for thumbnail loading
    const [loadingPopup, setLoadingPopup] = useState(true); // State for popup image loading

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setLoadingPopup(true); // Reset popup loading state when closing
    };

    const handleThumbnailLoad = () => {
        setLoadingThumbnail(false); // Set loading to false when the thumbnail is loaded
    };

    const handleImageLoad = () => {
        setLoadingPopup(false); // Set loading to false when the popup image is loaded
    };

    const handleImageError = () => {
        setLoadingPopup(false); // Stop loading if there's an error
        // You can also set a fallback image or state here if needed
    };

    return (
        <>
            <img
                src={props.fullResolution}
                className={styles.image}
                alt={props.alt}
                onClick={handleClickOpen}
                onLoad={handleThumbnailLoad} // Trigger thumbnail loading state change
                style={{ display: loadingThumbnail ? "none" : "block" }} // Hide thumbnail until loaded
            />
            {loadingThumbnail && (
                <BarLoader
                    loading={loadingThumbnail}
                    width={100}
                    className={styles.loader} // Optional styling class for the loader
                />
            )}
            <Dialog
                onClose={handleClose}
                open={open}
                PaperProps={{ className: styles.popup }}
                aria-labelledby="image-popup-dialog"
            >
                <div className={styles.dialogContent}>
                    {loadingPopup && (
                        <BarLoader
                            loading={loadingPopup}
                            width={100}
                        />
                    )}
                    <img
                        src={props.fullResolution}
                        alt={props.alt}
                        onLoad={handleImageLoad} // Trigger loading state change for popup image
                        onError={handleImageError} // Handle image load errors
                        onClick={handleClose}
                        className={styles.popupImage}
                        style={{ display: loadingPopup ? "none" : "block" }} // Hide popup image until loaded
                    />
                    <Button
                        onClick={handleClose}
                        variant="contained"
                        color="primary"
                        className={styles.closeButton} // Optional styling class
                    >
                        Close
                    </Button>
                </div>
            </Dialog>
        </>
    );
}

export default ImagePopup;
