import { useState } from "react";
import { useGetReservationsQuery } from '../reservations/reservationsApiSlice';
import { Box, Table, TableBody, TableCell, TableContainer, TableRow, Button, Paper } from '@mui/material';

const EditTransactionForm = ({ transaction }) => {
    const { reservation } = useGetReservationsQuery("reservationsList", {
        selectFromResult: ({ data }) => ({
            reservation: data?.entities[transaction?.reservationId],
        }),
    });

    const [chargeIsHidden, setChargeIsHidden] = useState(true);

    const onChargeClick = () => setChargeIsHidden(!chargeIsHidden);

    // Map the payment intent to table rows
    const paymentIntentArray = Object.entries(transaction?.paymentIntent).map(([name, value]) => ({
        name,
        value,
    }));

    // Remove source and refunds from the payment intent
    const paymentIntentTable = paymentIntentArray.map((charge) => {
        if (typeof charge.value !== "object" || charge?.value === null)
            return (
                <TableRow key={charge.name}>
                    <TableCell>
                        {charge.name}: {charge.value ? charge.value : "No data"}
                    </TableCell>
                </TableRow>
            );
        return null;
    });

    return (
        <Box sx={{ p: 3, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>

            <TableContainer component={Paper} sx={{ mb: 3 }}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={4} align="center" sx={{ py: 2, fontSize: '1rem', color: 'text.secondary' }}>
                                Transaction for {reservation?.guest?.name}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Payee:</TableCell>
                            <TableCell>{transaction?.paymentIntent?.customer || "No Data"}</TableCell>
                            <TableCell>Amount: {(transaction?.paymentIntent?.amount_received / 100) + " " + transaction?.paymentIntent?.currency}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Date:</TableCell>
                            <TableCell>{new Date(transaction?.createdAt).toLocaleString()}</TableCell>
                            <TableCell>
                                <Button variant="contained" color="primary" onClick={onChargeClick}>
                                    {chargeIsHidden ? 'Show Payment Intent' : 'Hide Payment Intent'}
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            {chargeIsHidden ? null : (
                <TableContainer component={Paper} sx={{ mb: 3 }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={4} align="center" sx={{ py: 2, fontSize: '1rem', color: 'text.secondary' }}>
                                    Transaction details for {transaction?.reservationId}
                                </TableCell>
                            </TableRow>
                            {paymentIntentTable}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Box>
    );
};

export default EditTransactionForm;
