import React from 'react'
// import SendAMessage from '../components/SendAMessage'
import Directions from '../components/Directions'

const ContactUs = () => {
    return (
        <>
            <Directions />
            {/* <SendAMessage /> */}
        </>
    )
}

export default ContactUs