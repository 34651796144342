import React, { useState, useEffect } from 'react'
import Calendar from 'react-calendar'
import style from './Calendar.module.css'
import Reservation from '../reservations/Reservation'
import 'react-calendar/dist/Calendar.css'

const MyCalendar = ({ dateOccupiedObjects }) => {

    const datesOccupied = dateOccupiedObjects.map((obj) => new Date(obj.date))

    const [value, setValue] = useState(new Date())
    const [foundReservationId, setFoundReservationId] = useState()

    useEffect(() => {
        const reservation = dateOccupiedObjects.find(dateOccupiedObject => new Date(dateOccupiedObject.date).toLocaleDateString() === value.toLocaleDateString())

        setFoundReservationId(reservation?.reservationId)
        // eslint-disable-next-line
    }, [value])

    function hasDate(testDate) {

        return !!datesOccupied.find(date => date.toLocaleDateString() === testDate.toLocaleDateString())
    }

    return (
        <>
            <Calendar
                minDetail='month'
                onChange={setValue}
                value={value}
                //highlight occupied dates
                tileClassName={({ date }) => {
                    if (hasDate(date)) {
                        return style.highlight
                    }
                    return null
                }}
            />
            <h1>{value.toLocaleDateString()}</h1>
            {/* <table className={`table table--reservation ${style.table}`}>
                <tbody>
                    {foundReservationId ? <Reservation key={foundReservationId} reservationId={foundReservationId} /> : <tr><td>Vacant</td></tr>}
                </tbody>
            </table> */}
            {foundReservationId ? <Reservation key={foundReservationId} reservationId={foundReservationId} /> : <h2>Vacant</h2>}
        </>
    )
}

export default MyCalendar