import React, { useState, useEffect, useCallback } from 'react';
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Import from react-i18next
import styles from './PublicHeader.module.css';

const PublicHeader = () => {
    const [transparent, setTransparent] = useState(false);
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { t, i18n } = useTranslation(); // Translation hook
    const BOOK_REGEX = /^\/book(\/)?$/; // Regular expression to match /book

    // Get hotel name from environment variable
    const hotelName = process.env.REACT_APP_HOTEL_NAME;

    const scrollHandler = useCallback(() => {
        const scrollY = window.scrollY;
        if ((scrollY > 0) !== transparent) {
            setTransparent(scrollY > 0);
        }
    }, [transparent]);

    useEffect(() => {
        // Add scroll event listener
        window.addEventListener('scroll', scrollHandler);
        return () => {
            // Cleanup event listener on unmount
            window.removeEventListener('scroll', scrollHandler);
        };
    }, [scrollHandler]);

    // Language toggle handler
    const toggleLanguage = () => {
        const newLang = i18n.language === 'en' ? 'th' : 'en'; // Switch between 'en' and 'th'
        i18n.changeLanguage(newLang);
    };

    // Navigation handlers
    const handleBookNow = () => navigate('/book');
    const handleHome = () => navigate('/'); // Navigate to home
    const handleContactUs = () => navigate('/contactus');

    let button = null;
    const buttonClass = transparent
        ? `${styles.transparent}`
        : `${styles.notTransparent}`;

    if (BOOK_REGEX.test(pathname)) {
        button = (
            <Button
                aria-label="Go to home"
                color="inherit"
                variant={transparent ? 'outlined' : 'contained'}
                className={buttonClass}
                onClick={handleHome}
                sx={{ mx: 1 }}
            >
                {t('PublicHeader.Home')}
            </Button>
        );
    } else {
        button = (
            <Button
                aria-label="Book now"
                color="inherit"
                variant={transparent ? 'outlined' : 'contained'}
                className={buttonClass}
                onClick={handleBookNow}
                sx={{ mx: 1 }}
            >
                {t('PublicHeader.Book Now')}
            </Button>
        );
    }

    const contactUs = (
        <Button
            aria-label="Contact us"
            color="inherit"
            variant={transparent ? 'outlined' : 'contained'}
            className={buttonClass}
            onClick={handleContactUs}
            sx={{ mx: 1 }}
        >
            {t('PublicHeader.Contact Us')}
        </Button>
    );

    // Language toggle button
    const languageToggle = (
        <Button
            aria-label="Toggle language"
            color="inherit"
            variant={transparent ? 'outlined' : 'contained'}
            className={buttonClass}
            onClick={toggleLanguage}
            sx={{ mx: 1 }}
        >
            {i18n.language === 'en' ? 'ไทย' : 'EN'}
        </Button>
    );

    return (
        <AppBar
            position="sticky"
            style={{
                backgroundColor: transparent ? 'transparent' : 'black',
                transition: '0.4s',
                borderTopLeftRadius: '16px',
                borderTopRightRadius: '16px',
            }}
        >
            <Toolbar>
                <Typography
                    variant="h6"
                    component="div"
                    className={styles.outline}
                    onClick={handleHome}
                    sx={{ cursor: 'pointer' }}
                >
                    {hotelName}
                </Typography>
                <Box
                    sx={{
                        flexGrow: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {button}
                    {contactUs}
                </Box>
                {/* Move language toggle button to the right side */}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {languageToggle}
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default PublicHeader;
