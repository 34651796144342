import React, { useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PulseLoader from 'react-spinners/PulseLoader';
import { useGetPublicReservationsQuery } from '../components/publicReservationsApiSlice';
import { Box, Typography, Button, Grid } from '@mui/material';
import html2canvas from 'html2canvas';
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook
import i18n from 'i18next'; // Import i18n instance for language detection

const Receipt = () => {
    const { t } = useTranslation();  // Use the 't' function for translations
    const { state } = useLocation();
    const navigate = useNavigate();
    const receiptRef = useRef(); // Create a ref for the receipt box

    const reservationId = state?.reservation?.data?.message;

    const { reservation, error, isLoading } = useGetPublicReservationsQuery("reservationsList", {
        selectFromResult: ({ data }) => ({
            reservation: data?.entities[reservationId],
        }),
    });

    const goBack = () => navigate('/');

    // Handle loading state
    if (isLoading) {
        return <PulseLoader color={"#FFF"} />;
    }

    // Handle error or reservation not found
    if (error || !reservation) {
        return (
            <Box
                sx={{
                    maxWidth: 600,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    borderRadius: '8px',
                    padding: '15px',
                    margin: '20px auto',
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                }}
                aria-live="assertive"
            >
                <Typography variant="h6" color="error">
                    {error ? t('Receipt.errorFetchingData') : t('Receipt.reservationNotFound')} {/* Translated error message */}
                </Typography>
                <Button
                    variant="contained"
                    onClick={goBack}
                    sx={{ backgroundColor: '#FF5733', color: '#FFF', padding: '10px 20px', marginTop: '20px', alignSelf: 'flex-end' }}
                >
                    {t('Receipt.return')} {/* Translated button text */}
                </Button>
            </Box>
        );
    }

    const { guest, checkInDate, checkOutDate, room, paymentAmount, paymentStatus, note } = reservation;

    const formatDate = (date) => {
        if (!date) return '';

        // Get the current language from i18next
        const currentLang = i18n.language;

        // Format the date based on the selected language
        return new Date(date).toLocaleDateString(currentLang, {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        });
    };

    const renderLabelValue = (label, value) => (
        <Box display="flex" flexDirection="column" marginBottom={1}>
            <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '2.7vh' }}>{label}</Typography>
            <Typography variant="body1" sx={{ fontSize: '2.5vh' }}>{value}</Typography>
        </Box>
    );

    const downloadReceiptAsImage = () => {
        html2canvas(receiptRef.current).then((canvas) => {
            const link = document.createElement('a');
            link.href = canvas.toDataURL('image/jpeg'); // Convert the canvas to JPEG
            link.download = 'receipt.jpg'; // Set the file name
            link.click(); // Trigger the download
        });
    };

    const hotelName = process.env.REACT_APP_HOTEL_NAME; // Import hotel name from environment variable

    return (
        <Box
            ref={receiptRef} // Attach the ref to the box
            sx={{
                maxWidth: 600,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                borderRadius: '8px',
                padding: '15px',
                margin: '20px auto',
                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
            }}
        >
            <Typography
                variant="h4"
                sx={{
                    fontSize: '3vh',
                    fontWeight: 'bold',
                    fontStyle: 'italic',
                    border: '2px solid black', // Black border
                    borderRadius: '8px', // Rounded corners
                    padding: '10px',
                    textAlign: 'center', // Center the text
                    marginBottom: 2,
                    width: '96%', // Make it full width
                }}
            >
                {hotelName}
            </Typography>
            <Typography variant="h4" sx={{ fontSize: '3vh', mb: 2 }}>
                {t('Receipt.reservationMessage', { guest: guest?.name, checkInDate: formatDate(checkInDate), checkOutDate: formatDate(checkOutDate) })}
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    {renderLabelValue(t('Receipt.room'), room)}
                    {renderLabelValue(t('Receipt.guestName'), guest?.name)}
                    {renderLabelValue(t('Receipt.email'), guest?.email)}
                    {renderLabelValue(t('Receipt.phoneNumber'), guest?.phone)}
                    {renderLabelValue(t('Receipt.adults'), guest?.adults)}
                    {guest?.kids > 0 && renderLabelValue(t('Receipt.children', { count: guest.kids }), guest.kids)}
                </Grid>
                <Grid item xs={12} md={6}>
                    {renderLabelValue(t('Receipt.checkInDate'), formatDate(checkInDate))}
                    {renderLabelValue(t('Receipt.checkOutDate'), formatDate(checkOutDate))}
                    {renderLabelValue(t('Receipt.paymentAmount'), `฿${paymentAmount}`)}
                    {renderLabelValue(t('Receipt.paymentStatus'), paymentStatus)}
                    {note && renderLabelValue(t('Receipt.note'), note)}
                </Grid>
            </Grid>
            <Box sx={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Button
                    variant="contained"
                    onClick={goBack}
                    sx={{ backgroundColor: '#FF5733', color: '#FFF', padding: '10px 20px' }}
                >
                    {t('Receipt.return')} {/* Translated button text */}
                </Button>
                <Button
                    variant="contained"
                    onClick={downloadReceiptAsImage}
                    sx={{ backgroundColor: '#007BFF', color: '#FFF', padding: '10px 20px' }}
                >
                    {t('Receipt.downloadReceipt')} {/* Translated button text */}
                </Button>
            </Box>
        </Box>
    );
};

export default Receipt;
