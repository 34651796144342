import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from 'react-router-dom';
import useAuth from "../hooks/useAuth";
import styles from './DashFooter.module.css'; // Import the CSS module

const DashFooter = () => {
    const { username, status } = useAuth();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const onGoHomeClicked = () => navigate('/dash');

    const date = new Date();
    const today = new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'long' }).format(date);

    let goHomeButton = null;
    if (pathname !== '/dash') {
        goHomeButton = (
            <button
                className={`${styles.button} icon-button`}
                title="Home"
                aria-label="Go to Home"
                onClick={onGoHomeClicked}
            >
                <FontAwesomeIcon icon={faHouse} />
            </button>
        );
    }

    const content = (
        <footer className={styles.dashFooter}>
            {goHomeButton}
            <div className={styles.dashFooterContent}>
                <p className={styles.user}>Current User: {username || 'Guest'}</p>
                <p className={styles.date}>{today}</p> {/* Date moved here */}
                <p className={styles.status}>Status: {status || 'N/A'}</p>
            </div>
        </footer>
    );
    return content;
}

export default DashFooter;
