import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useGetUsersQuery } from "./usersApiSlice";
import { memo } from "react";
import { TableRow, TableCell, IconButton } from "@mui/material";

const User = ({ userId }) => {
    const { user } = useGetUsersQuery("usersList", {
        selectFromResult: ({ data }) => ({
            user: data?.entities[userId],
        }),
    });

    const navigate = useNavigate();

    if (user) {
        const handleEdit = () => navigate(`/dash/users/${userId}`);

        const userRolesString = user.roles.toString().replaceAll(",", ", ");

        const cellStyles = {
            opacity: user.active ? 1 : 0.5,
            color: user.active ? "inherit" : "text.disabled",
        };

        return (
            <TableRow>
                <TableCell sx={cellStyles}>{user.username}</TableCell>
                <TableCell sx={cellStyles}>{userRolesString}</TableCell>
                <TableCell>
                    <IconButton color="primary" onClick={handleEdit}>
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </IconButton>
                </TableCell>
            </TableRow>
        );
    } else return null;
};

const memoizedUser = memo(User);

export default memoizedUser;
