import React, { useState, useEffect } from "react";
import { useUpdateRoomMutation, useDeleteRoomMutation } from "./roomsApiSlice";
import { useNavigate } from "react-router-dom";
import { Box, Typography, TextField, Button, CircularProgress, Alert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import useAuth from "../../hooks/useAuth";

const EditRoomForm = ({ room }) => {
    const { isManager, isAdmin } = useAuth();

    const [updateRoom, { isLoading, isSuccess, error }] = useUpdateRoomMutation();
    const [deleteRoom, { isSuccess: isDelSuccess, error: delerror }] = useDeleteRoomMutation();

    const navigate = useNavigate();

    const [roomName, setRoomName] = useState(room.roomName);
    const [roomPrice, setRoomPrice] = useState(room.roomPrice);
    const [roomDescription, setRoomDescription] = useState(room.roomDescription);
    const [roomImages, setRoomImages] = useState(room.roomImages);
    const [imageLabels, setImageLabels] = useState(room.imageLabels);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const datesOccupied = room.datesOccupied

    useEffect(() => {
        if (isSuccess || isDelSuccess) {
            navigate('/dash/rooms');
        }
    }, [isSuccess, isDelSuccess, navigate]);

    const handleInputChange = (setter) => (e) => setter(e.target.value);

    const handleArrayChange = (array, index, value, setter) => {
        const newArray = [...array];
        newArray[index] = value;
        setter(newArray);
    };

    const canSave = [roomName, datesOccupied, roomPrice, roomDescription, ...roomImages, ...imageLabels].every(Boolean) && !isLoading;

    const onSaveRoomClicked = async () => {
        if (canSave) {
            await updateRoom({ id: room.id, roomName, datesOccupied, roomPrice, roomDescription, roomImages, imageLabels });
        }
    };

    const openDeleteDialog = () => {
        setIsDialogOpen(true);
    };

    const closeDeleteDialog = () => {
        setIsDialogOpen(false);
    };

    const confirmDelete = async () => {
        closeDeleteDialog();
        await deleteRoom({ id: room.id });
    };

    const errorMessage = error?.data?.message || delerror?.data?.message;

    return (
        <Box
            sx={{
                maxWidth: 600,
                margin: '0 auto',
                padding: 4,
                boxShadow: 2,
                borderRadius: 2,
                bgcolor: 'background.paper',
                color: 'black'
            }}
        >
            <Typography variant="h4" component="h2" mb={3}>
                Edit Room: {room.roomName}
            </Typography>

            {errorMessage && (
                <Alert severity="error" sx={{ mb: 2 }}>
                    {errorMessage}
                </Alert>
            )}

            <Box
                component="form"
                onSubmit={(e) => e.preventDefault()}
                sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
            >
                <TextField
                    label="Room Name"
                    value={roomName}
                    onChange={handleInputChange(setRoomName)}
                    required
                />
                <TextField
                    label="Room Price"
                    type="number"
                    value={roomPrice}
                    onChange={handleInputChange(setRoomPrice)}
                    required
                />
                <TextField
                    label="Room Description"
                    value={roomDescription}
                    onChange={handleInputChange(setRoomDescription)}
                    required
                />
                {roomImages.map((image, index) => (
                    <Box key={index} sx={{ display: 'flex', gap: 2 }}>
                        <TextField
                            label={`Image ${index + 1} URL`}
                            value={image}
                            onChange={(e) =>
                                handleArrayChange(roomImages, index, e.target.value, setRoomImages)
                            }
                        />
                        <TextField
                            label={`Image ${index + 1} Label`}
                            value={imageLabels[index]}
                            onChange={(e) =>
                                handleArrayChange(imageLabels, index, e.target.value, setImageLabels)
                            }
                        />
                    </Box>
                ))}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                    {(isManager || isAdmin) && (
                        <Button
                            variant="contained"
                            color="error"
                            startIcon={<FontAwesomeIcon icon={faTrashCan} />}
                            onClick={openDeleteDialog}
                        >
                            Delete
                        </Button>
                    )}
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<FontAwesomeIcon icon={faSave} />}
                        onClick={onSaveRoomClicked}
                        disabled={!canSave}
                    >
                        {isLoading ? <CircularProgress size={24} /> : 'Save'}
                    </Button>
                </Box>
            </Box>

            {/* Confirmation Dialog */}
            <Dialog
                open={isDialogOpen}
                onClose={closeDeleteDialog}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
            >
                <DialogTitle id="delete-dialog-title">Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-dialog-description">
                        Are you sure you want to delete this room? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={confirmDelete} color="error">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default EditRoomForm;
