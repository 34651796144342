import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import styles from './Canceled.module.css';
import { useNavigate } from 'react-router-dom';

const Canceled = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        maxWidth: 600,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        borderRadius: '8px',
        padding: '15px',
        margin: '20px auto',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
      }}
      aria-live="assertive"
    >
      <Typography variant="h4" component="h1" gutterBottom sx={{ m: 'auto' }}>
        Payment Canceled
      </Typography>
      <Typography variant="body1" className={styles.message}>
        Payment has been canceled.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        className={styles.button}
        onClick={() => navigate('/dash/reservations')}
        sx={{ marginTop: '20px' }}
      >
        Back to Reservations
      </Button>
    </Box>
  );
};

export default Canceled;
