import React, { useEffect } from 'react';
import { Container, Typography, Button } from '@mui/material';
import styles from './PublicCanceled.module.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { usePublicDeleteReservationMutation } from '../components/publicReservationsApiSlice';
import { useTranslation } from 'react-i18next';

const PublicCanceled = () => {
    const { t } = useTranslation();  // Use the 't' function for translation
    const navigate = useNavigate();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const reservationId = params.get('reservation_id');

    const [deleteReservation, { isSuccess: isDelSuccess, isError: isDelError, error: delError }] = usePublicDeleteReservationMutation();

    useEffect(() => {
        const handleDeleteReservation = async () => {
            if (reservationId) {
                try {
                    await deleteReservation({ id: reservationId });
                } catch (error) {
                    console.error('Error deleting reservation:', error);
                }
            }
        };

        handleDeleteReservation();
    }, [reservationId, deleteReservation]);

    useEffect(() => {
        if (isDelError) {
            console.error('Error deleting reservation:', delError);
        }
    }, [isDelSuccess, isDelError, delError, navigate]);

    return (
        <Container className={styles.container} maxWidth="sm">
            <Typography variant="h4" component="h1" gutterBottom>
                {t('PublicCanceled.bookingCanceled')}  {/* Translated title */}
            </Typography>
            <Typography variant="body1" className={styles.message}>
                {t('PublicCanceled.cancelMessage')} {/* Translated message */}
            </Typography>
            <Button
                variant="contained"
                color="primary"
                className={styles.button}
                onClick={() => navigate('/')}
            >
                {t('PublicCanceled.backToHomepage')} {/* Translated button */}
            </Button>
        </Container>
    );
};

export default PublicCanceled;
