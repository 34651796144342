import { useGetReservationsQuery } from "./reservationsApiSlice"
import Reservation from '../reservations/Reservation'
import PulseLoader from 'react-spinners/PulseLoader'
import { useState, useMemo } from "react"
import styles from './ReservationsList.module.css'

const ReservationList = () => {
    const [searchValue, setSearchValue] = useState('')
    const [checked, setChecked] = useState(false)

    const handleSearch = (e) => setSearchValue(e.target.value.toLowerCase())
    const handleCheckboxChange = (e) => setChecked(e.target.checked)

    const {
        data: reservation,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetReservationsQuery('reservationsList', {
        pollingInterval: 5000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    const filteredReservations = useMemo(() => {
        if (!isSuccess || !reservation?.ids?.length) return []

        const sortedByDates = reservation.ids
            .map(id => reservation.entities[id])
            .sort((a, b) => new Date(a.checkInDate) - new Date(b.checkInDate))

        const futureDates = sortedByDates.filter(res => new Date(res.checkOutDate) > new Date())

        const searchFiltered = sortedByDates.filter(res =>
            res.guest?.name?.toLowerCase().includes(searchValue)
        )

        const finalList = checked ? searchFiltered : futureDates.filter(res =>
            searchFiltered.some(fRes => fRes.id === res.id)
        )

        return finalList.map(res => <Reservation key={res.id} reservationId={res.id} />)
    }, [reservation, searchValue, checked, isSuccess])

    let content

    if (isLoading) {
        content = <PulseLoader color="#FFF" />
    } else if (isError) {
        content = <p className="errmsg">{error?.data?.message}</p>
    } else if (isSuccess) {
        content = (
            <>
                <div className={styles.searchBar}>
                    <label htmlFor="reservation-name">Reservation:</label>
                    <input
                        type="search"
                        placeholder="Search here!"
                        onChange={handleSearch}
                        id="reservation-name"
                        className={styles.searchInput}
                    />
                    <div className={styles.checkboxContainer}>
                        <input
                            type="checkbox"
                            checked={checked}
                            onChange={handleCheckboxChange}
                            className={styles.checkbox}
                        />
                        <label>All results</label>
                    </div>
                </div>
                {filteredReservations.length > 0 ? filteredReservations : <h2 className={styles.noResults}>--No Results--</h2>}
            </>
        )
    }

    return content
}

export default ReservationList
