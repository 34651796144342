import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAddNewRoomMutation } from "./roomsApiSlice";
import {
    Box,
    Typography,
    TextField,
    Button,
    CircularProgress,
    Alert,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";

const NewRoomForm = () => {
    const [addNewRoom, { isLoading, isSuccess, isError, error }] =
        useAddNewRoomMutation();

    const navigate = useNavigate();

    const [roomName, setRoomName] = useState("");
    const [roomPrice, setRoomPrice] = useState("");
    const [roomDescription, setRoomDescription] = useState("");
    const [roomImages, setRoomImages] = useState(["", "", "", ""]); // Array to store room image URLs
    const [imageLabels, setImageLabels] = useState(["", "", "", ""]); // Array to store image labels

    useEffect(() => {
        if (isSuccess) {
            setRoomName("");
            setRoomPrice("");
            setRoomDescription("");
            setRoomImages(["", "", "", ""]);
            setImageLabels(["", "", "", ""]);
            navigate("/dash/rooms");
        }
    }, [isSuccess, navigate]);

    const canSave = [
        roomName,
        roomPrice,
        roomDescription,
        ...roomImages,
        ...imageLabels,
    ].every(Boolean) && !isLoading;

    const onSaveRoomClicked = async (e) => {
        e.preventDefault();
        if (canSave) {
            await addNewRoom({
                roomName,
                roomPrice,
                roomDescription,
                roomImages,
                imageLabels,
            });
        }
    };

    // Handle changes for image URLs and labels
    const handleImageChange = (index, type) => (e) => {
        if (type === "label") {
            const updatedLabels = [...imageLabels];
            updatedLabels[index] = e.target.value;
            setImageLabels(updatedLabels);
        } else {
            const updatedImages = [...roomImages];
            updatedImages[index] = e.target.value;
            setRoomImages(updatedImages);
        }
    };

    return (
        <Box
            sx={{
                maxWidth: 600,
                mx: "auto",
                p: 3,
                bgcolor: "background.paper",
                boxShadow: 3,
                borderRadius: 2,
                color: 'black'
            }}
        >
            {isError && (
                <Alert severity="error" sx={{ mb: 2 }}>
                    {error?.data?.message}
                </Alert>
            )}

            <Typography variant="h4" gutterBottom>
                Create New Room
            </Typography>

            <form onSubmit={onSaveRoomClicked}>
                <TextField
                    label="Room Name"
                    variant="outlined"
                    fullWidth
                    sx={{ mb: 2 }}
                    value={roomName}
                    onChange={(e) => setRoomName(e.target.value)}
                    required
                />

                <TextField
                    label="Room Price"
                    type="number"
                    variant="outlined"
                    fullWidth
                    sx={{ mb: 2 }}
                    value={roomPrice}
                    onChange={(e) => setRoomPrice(e.target.value)}
                    required
                />

                <TextField
                    label="Room Description"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={3}
                    sx={{ mb: 2 }}
                    value={roomDescription}
                    onChange={(e) => setRoomDescription(e.target.value)}
                    required
                />

                {[1, 2, 3, 4].map((i) => (
                    <Box key={i} sx={{ mb: 2 }}>
                        <TextField
                            label={`Image Label ${i}`}
                            variant="outlined"
                            fullWidth
                            sx={{ mb: 1 }}
                            value={imageLabels[i - 1]} // Use the index to access the label
                            onChange={handleImageChange(i - 1, "label")}
                            required
                        />
                        <TextField
                            label={`Image URL ${i}`}
                            variant="outlined"
                            fullWidth
                            value={roomImages[i - 1]} // Use the index to access the image URL
                            onChange={handleImageChange(i - 1, "image")}
                            required
                        />
                    </Box>
                ))}

                <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={!canSave}
                        startIcon={
                            isLoading ? (
                                <CircularProgress size={20} />
                            ) : (
                                <FontAwesomeIcon icon={faSave} />
                            )
                        }
                    >
                        Save
                    </Button>
                </Box>
            </form>
        </Box>
    );
};

export default NewRoomForm;
