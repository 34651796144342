import { useGetTransactionsQuery } from "./transactionsApiSlice";
import Transaction from "./Transaction";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
    CircularProgress,
    Alert,
    Paper,
} from "@mui/material";

const TransactionsList = () => {
    const {
        data: transactions,
        isLoading,
        isSuccess,
        isError,
        error,
    } = useGetTransactionsQuery("transactionsList", {
        pollingInterval: 5000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
    });

    let content;

    if (isLoading) {
        content = (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
                <CircularProgress size={40} />
            </Box>
        );
    }

    if (isError) {
        content = (
            <Alert severity="error" sx={{ mt: 4 }}>
                {error?.data?.message || "An error occurred while fetching transactions."}
            </Alert>
        );
    }

    if (isSuccess) {
        const { ids } = transactions;

        const tableContent =
            ids?.length &&
            ids
                .map((transactionId) => (
                    <Transaction key={transactionId} transactionId={transactionId} />
                ))
                .reverse(); // Reverse the order

        content = (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 4,
                    width: "100%", // Full width for flexibility,
                }}
            >
                <TableContainer
                    component={Paper}
                    sx={{
                        boxShadow: 3,
                        borderRadius: 2,
                        maxWidth: "600px", // Restrict table width
                        width: "100%",
                        height: "500px", // Fixed height for the table container
                        overflowY: "auto", // Enable vertical scrolling
                    }}
                >
                    <Table stickyHeader>
                        <TableBody>
                            {tableContent?.length ? (
                                tableContent
                            ) : (
                                <TableRow>
                                    <TableCell
                                        colSpan={3}
                                        align="center"
                                        sx={{ py: 3, fontSize: "1rem", color: "text.secondary" }}
                                    >
                                        No transactions found.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        );
    }

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                p: 3,
                color: 'white'
            }}
        >
            <Typography variant="h4" gutterBottom>
                Transactions List
            </Typography>
            {content}
        </Box>
    );
};

export default TransactionsList;
