import { useGetUsersQuery } from "./usersApiSlice";
import User from "./User";
import { Box, Typography, CircularProgress, Alert, Table, TableBody, Button } from "@mui/material";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from "react-router-dom";

const UsersList = () => {
    const {
        data: users,
        isLoading,
        isSuccess,
        isError,
        error,
    } = useGetUsersQuery(undefined, {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
    });

    const navigate = useNavigate();

    let content;

    if (isLoading) {
        content = (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100px",
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    if (isError) {
        content = (
            <Alert severity="error" sx={{ mb: 2 }}>
                {error?.data?.message || "An error occurred while fetching users."}
            </Alert>
        );
    }

    if (isSuccess) {
        const { ids } = users;

        const tableContent =
            ids?.length && ids.map((userId) => <User key={userId} userId={userId} />);

        content = (
            <Table sx={{ border: "1px solid #ccc", borderRadius: "8px", mt: 2 }}>
                <TableBody>{tableContent}</TableBody>
            </Table>
        );
    }

    return (
        <Box
            sx={{
                maxWidth: 600,
                mx: "auto",
                p: 3,
                bgcolor: "background.paper",
                boxShadow: 3,
                borderRadius: 2,
            }}
        >
            <Typography variant="h4" sx={{ mb: 2, textAlign: "center" }}>
                Users List
            </Typography>
            {content}
            <Button
                variant="contained"
                color="primary"
                onClick={() => navigate(-1)}  // Navigate back to the previous page
                startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
                sx={{ marginTop: '10px' }}
            >
                Back
            </Button>
        </Box>
    );
};

export default UsersList;
