import { apiSlice } from "../app/api/apiSlice"

export const publicPayApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        publicPay: builder.mutation({
            query: submit => ({
                url: '/book/payment',
                method: 'POST',
                body: {
                    ...submit,
                }
            })
        }),
    }),
})

export const {
    usePublicPayMutation
} = publicPayApiSlice