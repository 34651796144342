import { store } from '../../app/store'
import { reservationsApiSlice } from '../reservations/reservationsApiSlice'
import { roomsApiSlice } from '../rooms/roomsApiSlice'
import { usersApiSlice } from '../users/usersApiSlice'
import { transactionsApiSlice } from '../transactions/transactionsApiSlice'
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

const Prefetch = () => {

    useEffect(() => {
        store.dispatch(reservationsApiSlice.util.prefetch('getReservations', 'reservationList', { force: true }))
        store.dispatch(roomsApiSlice.util.prefetch('getRooms', 'roomList', { force: true }))
        store.dispatch(usersApiSlice.util.prefetch('getUsers', 'usersList', { force: true }))
        store.dispatch(transactionsApiSlice.util.prefetch('getTransactions', 'transactionsList', { force: true }))
    }, [])

    return <Outlet />
}
export default Prefetch