import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit"
import { apiSlice } from "../app/api/apiSlice"

const publicRoomsAdapter = createEntityAdapter({})

const initialState = publicRoomsAdapter.getInitialState()

export const publicRoomsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getPublicRooms: builder.query({
            query: () => ({
                url: '/book',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
                const loadedPublicRooms = responseData.map(publicRoom => {
                    publicRoom.id = publicRoom._id
                    return publicRoom
                })
                return publicRoomsAdapter.setAll(initialState, loadedPublicRooms)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Room', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Room', id }))
                    ]
                } else return [{ type: 'Room', id: 'LIST' }]
            },
        })
    }),
})

export const {
    useGetPublicRoomsQuery,
} = publicRoomsApiSlice

// returns the query result object
export const selectPublicRoomsResult = publicRoomsApiSlice.endpoints.getPublicRooms.select()

// creates memoized selector
const selectPublicRoomsData = createSelector(
    selectPublicRoomsResult,
    publicRoomsResult => publicRoomsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllPublicRooms,
    selectById: selectPublicRoomById,
    selectIds: selectPublicRoomIds
    // Pass in a selector that returns the PublicRooms slice of state
} = publicRoomsAdapter.getSelectors(state => selectPublicRoomsData(state) ?? initialState)