import { Routes, Route } from 'react-router-dom'
import Layout from './components/Layout'
import Public from './pages/Public'
import Login from './features/auth/Login'
import DashLayout from './components/DashLayout'
import Welcome from './features/auth/Welcome'
import ReservationsList from './features/reservations/ReservationsList'
import EditReservation from './features/reservations/EditReservation'
import Prefetch from './features/auth/Prefetch'
import NewReservationForm from './features/reservations/NewReservationForm'
import RoomsList from './features/rooms/RoomsList'
import EditRoom from './features/rooms/EditRoom'
import NewRoomForm from './features/rooms/NewRoomForm'
import NewUserForm from './features/users/NewUserForm'
import UsersList from './features/users/UsersList'
import EditUser from './features/users/EditUser'
import PersistLogin from './features/auth/PersistLogin'
import RequireAuth from './features/auth/RequireAuth'
import { ROLES } from './config/roles'
import useTitle from './hooks/useTitle'
// import NewTransactionForm from './features/transactions/NewTransactionForm'
import TransactionsList from './features/transactions/TransactionsList'
import EditTransaction from './features/transactions/EditTransaction'
import Checkout from './features/payment/Checkout'
import Success from './features/payment/Success'
import Canceled from './features/payment/Canceled'
import HomePage from './pages/HomePage'
import InformationForm from './pages/InformationForm'
import PaymentForm from './pages/PaymentForm'
import Receipt from './pages/Receipt'
import PublicSuccess from './pages/PublicSuccess'
import PublicCanceled from './pages/PublicCanceled'
import PublicLayout from './components/PublicLayout'
import ContactUs from './pages/ContactUs'

function App() {
  // Get hotel name from environment variable
  const hotelName = process.env.REACT_APP_HOTEL_NAME;

  useTitle(hotelName)
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<PublicLayout />}>
          {/* public routes */}
          <Route index element={<HomePage />} />
          <Route path="login" element={<Login />} />
          <Route path="contactus" element={<ContactUs />} />

          <Route path="book">
            <Route index element={<Public />} />
            <Route path="information" element={<InformationForm />} />
            <Route path="payment">
              <Route index element={<PaymentForm />} />
              <Route path='success' element={<PublicSuccess />} />
              <Route path='canceled' element={<PublicCanceled />} />
            </Route>
            <Route path="receipt" element={<Receipt />} />
          </Route>
        </Route>

        {/* Protected Routes */}
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth allowedRoles={[...Object.values(ROLES)]} />}>

            <Route element={<Prefetch />}>
              <Route path="dash" element={<DashLayout />}>

                <Route index element={<Welcome />} />

                <Route path="payment">
                  <Route index element={<Checkout />} />
                  <Route path='success' element={<Success />} />
                  <Route path='canceled' element={<Canceled />} />
                </Route>

                <Route element={<RequireAuth allowedRoles={[ROLES.Manager, ROLES.Admin]} />}>
                  <Route path="users">
                    <Route index element={<UsersList />} />
                    <Route path=":id" element={<EditUser />} />
                    <Route path="new" element={<NewUserForm />} />
                  </Route>
                </Route>

                <Route element={<RequireAuth allowedRoles={[ROLES.Manager, ROLES.Admin, ROLES.Employee]} />}>
                  <Route path="reservations">
                    <Route index element={<ReservationsList />} />
                    <Route path=":id" element={<EditReservation />} />
                    <Route path="new" element={<NewReservationForm />} />
                  </Route>
                </Route>

                <Route element={<RequireAuth allowedRoles={[ROLES.Manager, ROLES.Admin, ROLES.Employee]} />}>
                  <Route path="rooms">
                    <Route index element={<RoomsList />} />
                    <Route path=":id" element={<EditRoom />} />
                    <Route element={<RequireAuth allowedRoles={[ROLES.Manager, ROLES.Admin]} />}>
                      <Route path="new" element={<NewRoomForm />} />
                    </Route>
                  </Route>
                </Route>

                <Route element={<RequireAuth allowedRoles={[ROLES.Manager, ROLES.Admin, ROLES.Employee]} />}>
                  <Route path="transactions">
                    <Route index element={<TransactionsList />} />
                    <Route path=":id" element={<EditTransaction />} />
                    {/* <Route path="new" element={<NewTransactionForm />} /> */}
                  </Route>
                </Route>

              </Route>{/* End Dash */}
            </Route>
          </Route>
        </Route>{/* End Protected Routes */}
      </Route>
    </Routes>
  )
}

export default App 
