import { useState, useEffect } from 'react';
import * as React from "react";
import RoomCard from '../components/RoomCard';
import { Grid } from "@mui/material";
import styles from './Public.module.css';
import { useGetPublicRoomsQuery } from '../components/publicRoomsApiSlice';
import PulseLoader from 'react-spinners/PulseLoader';
import DatePicker from "react-datepicker"; // Import DatePicker
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

// Import date-fns locales
import { enUS, th } from 'date-fns/locale';

const Public = () => {
    const { t, i18n } = useTranslation(); // Hook to access translation function and i18n instance
    const { data: rooms, isLoading, isError, error, isSuccess } = useGetPublicRoomsQuery("roomsList", {
        pollingInterval: 5000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    });

    // Set today's date
    const today = new Date();
    const [startDate, setStartDate] = useState(today);
    const [endDate, setEndDate] = useState(getTomorrowDate(today));

    // Determine locale based on i18next language state
    const currentLanguage = i18n.language;
    const locale = currentLanguage === 'th' ? th : enUS;

    useEffect(() => {
        // Automatically set end date to the next day when start date changes
        setEndDate(getTomorrowDate(startDate));
    }, [startDate]);

    // Helper function to get tomorrow's date
    function getTomorrowDate(date) {
        const tomorrowDate = new Date(date);
        tomorrowDate.setDate(tomorrowDate.getDate() + 1);
        return tomorrowDate;
    }

    let content;

    if (isLoading) {
        content = (
            <div className={styles.loader}>
                <PulseLoader color={"#FFF"} />
                <p>{t('Public.loadingRooms')}</p> {/* Using the translation key for loading text */}
            </div>
        );
    } else if (isError) {
        content = <p className={styles.errmsg}>{t('Public.errorFetching', { error: error?.data?.message })}</p>;
    } else if (isSuccess) {
        const roomObjects = rooms?.ids.map(id => rooms?.entities[id]);
        const vacantRoomsArray = getVacantRooms(roomObjects, startDate, endDate);

        const vacantRoomCards = vacantRoomsArray.map(room => (
            <Grid key={room.id} item xs={12} md={6} lg={3} className={styles.gridItem}>
                <RoomCard roomId={room.id} checkInDate={startDate.toISOString().split("T")[0]} checkOutDate={endDate.toISOString().split("T")[0]} />
            </Grid>
        ));

        content = (
            <section className={styles.public}>
                <header>
                    <div className={styles.header}>
                        <h1>{t('Public.hotelName', { hotelName: process.env.REACT_APP_HOTEL_NAME })}</h1>
                    </div>
                </header>
                <main className={styles.public__main}>
                    <div className={styles.publicDateContainer}>
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item>
                                <label className={styles.form__label} htmlFor="reservation-checkInDate">{t('Public.checkInDate')}</label>
                                <DatePicker
                                    className={styles.formInput}
                                    selected={startDate}
                                    onChange={(date) => {
                                        setStartDate(date);
                                        if (date) {
                                            setEndDate(getTomorrowDate(date));
                                        }
                                    }}
                                    selectsStart
                                    startDate={startDate}
                                    endDate={endDate}
                                    minDate={today}
                                    locale={locale}
                                    dateFormat="MMMM d, yyyy"
                                />
                            </Grid>
                            <Grid item>
                                <label className={styles.form__label} htmlFor="reservation-checkOutDate">{t('Public.checkOutDate')}</label>
                                <DatePicker
                                    className={styles.formInput}
                                    selected={endDate}
                                    onChange={(date) => setEndDate(date)}
                                    selectsEnd
                                    startDate={startDate}
                                    endDate={endDate}
                                    minDate={startDate}
                                    locale={locale}
                                    dateFormat="MMMM d, yyyy"
                                />
                            </Grid>
                        </Grid>
                    </div>
                    <h1 className={styles.noVacancy}>{t('Public.availableRoomsMessage', { startDate: startDate.toLocaleDateString(), endDate: endDate.toLocaleDateString() })}</h1>
                    <Grid container spacing={2}>
                        {vacantRoomCards.length > 0 ? vacantRoomCards : <h1 className={styles.noVacancy}>{t('Public.noVacancy')}</h1>}
                    </Grid>
                </main>
            </section>
        );
    }

    return content;
};

// Function to find vacant rooms based on occupied dates
const getVacantRooms = (roomObjects, checkInDate, checkOutDate) => {
    let vacantRoomsArray = [];
    if (checkInDate && checkOutDate) {
        const testDates = getDatesBetween(checkInDate, checkOutDate);

        roomObjects.forEach(room => {
            const datesOccupiedSet = new Set(room?.datesOccupied.map(date => new Date(date?.date).toLocaleDateString()));
            const isVacant = testDates.every(testDate => !datesOccupiedSet.has(testDate.toLocaleDateString()));

            if (isVacant) {
                vacantRoomsArray.push(room);
            }
        });
    }
    return vacantRoomsArray;
};

// Helper function to get all dates between two dates
const getDatesBetween = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    let dates = [];
    let currentDate = new Date(start);

    if (start >= end) {
        return dates;
    }

    while (currentDate < end) {
        dates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
};

export default Public;
