import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit"
import { apiSlice } from "../../app/api/apiSlice"

const transactionsAdapter = createEntityAdapter({})

const initialState = transactionsAdapter.getInitialState()

export const transactionsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getTransactions: builder.query({
            query: () => ({
                url: '/transactions',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
                const loadedTransactions = responseData.map(transaction => {
                    transaction.id = transaction._id
                    return transaction
                })
                return transactionsAdapter.setAll(initialState, loadedTransactions)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    let tags = [{ type: 'Transaction', id: 'LIST' }, { type: 'Reservation', id: 'LIST' }, ...result.ids.map(id => ({ type: 'Reservation', id }))]
                    tags = [...result.ids.map(id => ({ type: 'Transaction', id }))]
                    return tags
                } else return [
                    { type: 'Transaction', id: 'LIST' },
                    { type: 'Reservation', id: 'LIST' }
                ]
            }
        }),
        addNewTransaction: builder.mutation({
            query: initialTransactionData => ({
                url: '/transactions',
                method: 'POST',
                body: {
                    ...initialTransactionData,
                }
            }),
            invalidatesTags: [
                { type: 'Transaction', id: "LIST" },
                { type: 'Reservation', id: 'LIST' }
            ]
        }),
        updateTransaction: builder.mutation({
            query: initialTransactionData => ({
                url: '/transactions',
                method: 'PATCH',
                body: {
                    ...initialTransactionData,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Transaction', id: arg.id }
            ]
        }),
        deleteTransaction: builder.mutation({
            query: ({ id }) => ({
                url: `/transactions`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Transaction', id: arg.id }
            ]
        }),
    }),
})

export const {
    useGetTransactionsQuery,
    useAddNewTransactionMutation,
    useUpdateTransactionMutation,
    useDeleteTransactionMutation,
} = transactionsApiSlice

// returns the query result object
export const selectTransactionsResult = transactionsApiSlice.endpoints.getTransactions.select()

// creates memoized selector
const selectTransactionsData = createSelector(
    selectTransactionsResult,
    transactionsResult => transactionsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllTransactions,
    selectById: selectTransactionById,
    selectIds: selectTransactionIds
    // Pass in a selector that returns the transactions slice of state
} = transactionsAdapter.getSelectors(state => selectTransactionsData(state) ?? initialState)