import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAddNewReservationMutation } from "./reservationsApiSlice";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faRedo } from "@fortawesome/free-solid-svg-icons";
import { useGetRoomsQuery } from '../rooms/roomsApiSlice';
import {
    TextField, Button, Select, MenuItem, FormControl,
    InputLabel, Typography, Alert, CircularProgress, Box
} from '@mui/material';

const NewReservationForm = () => {
    const [addNewReservation, { isLoading, isSuccess, isError, error }] = useAddNewReservationMutation();
    const { data: rooms, isFetching: roomsLoading } = useGetRoomsQuery('roomsList');
    const navigate = useNavigate();

    const roomNames = rooms?.ids.map(id => ({
        name: rooms?.entities[id]?.roomName,
        price: rooms?.entities[id]?.roomPrice
    })) || [];

    const [room, setRoom] = useState('');
    const [roomPrice, setRoomPrice] = useState(0);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [adults, setAdults] = useState(1);
    const [kids, setKids] = useState(0);
    const [checkInDate, setCheckInDate] = useState('');
    const [checkOutDate, setCheckOutDate] = useState('');
    const [paymentStatus, setPaymentStatus] = useState('pending');
    const [paymentAmount, setPaymentAmount] = useState('');
    const [note, setNote] = useState('');
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    useEffect(() => {
        if (isSuccess) {
            resetForm();
            setShowSuccessMessage(true);
            setTimeout(() => setShowSuccessMessage(false), 3000); // hide success message after 3 seconds
            navigate('/dash/reservations');
        }
    }, [isSuccess, navigate]);

    useEffect(() => {
        calculatePaymentAmount();
    }, [roomPrice, checkInDate, checkOutDate]);

    const resetForm = () => {
        setRoom('');
        setRoomPrice(0);
        setName('');
        setEmail('');
        setPhone('');
        setAdults(1);
        setKids(0);
        setCheckInDate('');
        setCheckOutDate('');
        setPaymentStatus('pending');
        setPaymentAmount('');
        setNote('');
    };

    const calculatePaymentAmount = () => {
        if (checkInDate && checkOutDate && roomPrice > 0) {
            const startDate = new Date(checkInDate);
            const endDate = new Date(checkOutDate);
            const nightsStayed = Math.max((endDate - startDate) / (1000 * 60 * 60 * 24), 0);
            setPaymentAmount(roomPrice * nightsStayed);
        } else {
            setPaymentAmount('');
        }
    };

    const onRoomChange = (e) => {
        const selectedRoom = roomNames.find(r => r.name === e.target.value);
        setRoom(e.target.value);
        setRoomPrice(selectedRoom?.price || 0);
    };

    const canSave = [room, name, email, phone, adults, checkInDate, checkOutDate, paymentAmount].every(Boolean) && !isLoading;

    const onSaveReservationClicked = async (e) => {
        e.preventDefault();
        if (canSave) {
            await addNewReservation({ room, name, email, phone, adults, kids, checkInDate, checkOutDate, paymentStatus, paymentAmount, note });
        }
    };

    const today = new Date().toISOString().split("T")[0];
    const tomorrowDate = new Date(checkInDate || today);
    tomorrowDate.setDate(tomorrowDate.getDate() + 1);
    const tomorrow = tomorrowDate.toISOString().split("T")[0];

    return (
        <Box sx={{ maxWidth: 600, mx: 'auto', p: 3, bgcolor: 'background.paper', boxShadow: 3, borderRadius: 2 }}>
            {isError && <Alert severity="error" sx={{ mb: 2 }}>{error?.data?.message}</Alert>}
            {showSuccessMessage && <Alert severity="success" sx={{ mb: 2 }}>Reservation saved successfully!</Alert>}

            <Typography variant="h4" gutterBottom>New Reservation</Typography>

            <form onSubmit={onSaveReservationClicked}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel>Room</InputLabel>
                    {roomsLoading ? (
                        <CircularProgress size={24} />
                    ) : (
                        <Select
                            value={room}
                            onChange={onRoomChange}
                            label="Room"
                        >
                            <MenuItem value=""><em>--Select a room--</em></MenuItem>
                            {roomNames.length > 0 ? roomNames.map(({ name, price }) => (
                                <MenuItem key={name} value={name}>{`${name} - ฿${price}/night`}</MenuItem>
                            )) : (
                                <MenuItem disabled>No rooms available</MenuItem>
                            )}
                        </Select>
                    )}
                </FormControl>

                <TextField
                    label="Name"
                    variant="outlined"
                    fullWidth
                    sx={{ mb: 2 }}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                />

                <TextField
                    label="Email"
                    type="email"
                    variant="outlined"
                    fullWidth
                    sx={{ mb: 2 }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />

                <TextField
                    label="Phone"
                    type="tel"
                    variant="outlined"
                    fullWidth
                    sx={{ mb: 2 }}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                />

                <TextField
                    label="Adults"
                    type="number"
                    variant="outlined"
                    fullWidth
                    sx={{ mb: 2 }}
                    value={adults}
                    onChange={(e) => setAdults(Math.max(0, e.target.value))}
                    inputProps={{ min: 0 }}
                    required
                />

                <TextField
                    label="Kids"
                    type="number"
                    variant="outlined"
                    fullWidth
                    sx={{ mb: 2 }}
                    value={kids}
                    onChange={(e) => setKids(Math.max(0, e.target.value))}
                    inputProps={{ min: 0 }}
                />

                <TextField
                    label="Check-in Date"
                    type="date"
                    variant="outlined"
                    fullWidth
                    sx={{ mb: 2 }}
                    value={checkInDate}
                    onChange={(e) => setCheckInDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ min: today }}
                    required
                />

                <TextField
                    label="Check-out Date"
                    type="date"
                    variant="outlined"
                    fullWidth
                    sx={{ mb: 2 }}
                    value={checkOutDate}
                    onChange={(e) => setCheckOutDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ min: tomorrow }}
                    required
                />

                <FormControl fullWidth sx={{ mb: 2 }}>
                    <InputLabel>Payment Status</InputLabel>
                    <Select
                        value={paymentStatus}
                        onChange={(e) => setPaymentStatus(e.target.value)}
                        label="Payment Status"
                    >
                        <MenuItem value="pending">Pending</MenuItem>
                        <MenuItem value="paid">Paid</MenuItem>
                        <MenuItem value="canceled">Canceled</MenuItem>
                    </Select>
                </FormControl>

                <TextField
                    label="Payment Amount"
                    type="number"
                    variant="outlined"
                    fullWidth
                    sx={{ mb: 2 }}
                    value={paymentAmount}
                    InputProps={{ readOnly: true }}
                />

                <TextField
                    label="Note"
                    variant="outlined"
                    fullWidth
                    sx={{ mb: 2 }}
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                />

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 2 }}>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={resetForm}
                        startIcon={<FontAwesomeIcon icon={faRedo} />}
                    >
                        Reset
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={!canSave}
                        startIcon={isLoading ? <CircularProgress size={20} /> : <FontAwesomeIcon icon={faSave} />}
                    >
                        Save
                    </Button>
                </Box>

            </form>
        </Box>
    );
};

export default NewReservationForm;
