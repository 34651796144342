import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend'; // For loading translations
import LanguageDetector from 'i18next-browser-languagedetector'; // For detecting user language

i18n
    // Load translation using http -> see /public/locales
    .use(Backend)
    // Detect user language
    .use(LanguageDetector)
    // Pass the i18n instance to react-i18next
    .use(initReactI18next)
    .init({
        fallbackLng: 'en', // Fallback language if detection fails
        debug: true, // Set to false in production

        interpolation: {
            escapeValue: false, // React already safes from xss
        },

        backend: {
            // Path to load translation files
            loadPath: '/locales/{{lng}}/translation.json',
        },

        react: {
            useSuspense: true, // Enable suspense for async loading
        },
    });

export default i18n;
